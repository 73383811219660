import api from '@/lib/api-client';
import { User } from '@/types/api';
import { UseQueryOptions, useMutation, useQueryClient, QueryKey, useQuery, UseMutationOptions } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { SnackBarContext } from 'pragmatic-ui';

const userKey = ['users'];

const getCustomerUsersByCompany = async (company: string): Promise<User[]> => {
    const response = await api.get(`/v1/customer/users/${company}`);
    return response.data;
};

export const useCustomerUsersByCompany = (
    company: string,
    options?: Omit<
        UseQueryOptions<User[], Error, User[], QueryKey>,
        'queryKey' | 'queryFn'
    >,
) =>
    useQuery({
        queryKey: userKey,
        queryFn: () => getCustomerUsersByCompany(company),
        ...options,
    });


async function createCustomerUser(payload: FormData): Promise<User> {
    const response = await api.post('/v1/customer/user', payload);
    return response.data;
}

export const useCreateCustomerUser = (
    options?: Omit<UseMutationOptions<User, Error, FormData>, 'mutationFn'>,
) => {
    const queryClient = useQueryClient();
    const { showSnack } = useContext(SnackBarContext);

    return useMutation({
        mutationFn: createCustomerUser,
        ...options,
        onSuccess: (uesr, ...rest) => {
            queryClient.invalidateQueries({ queryKey: userKey });
            options?.onSuccess?.(uesr, ...rest);
            showSnack('Successfully added new customer', 'success');
        },
        onError: () => {
            showSnack('There was an unexpected error, please try again', 'error');
        },
    });
};

async function updateCustomerUser(payload: FormData): Promise<User> {
    const userId = payload.get('_id');
    if(!userId){
        throw new Error('User id is required');
    }
    const response = await api.put(`/v1/customer/user/${userId}`, payload);
    return response.data;
}

export const useUpdateCustomerUser = (
    options?: Omit<UseMutationOptions<User, Error, FormData>, 'mutationFn'>,
) => {
    const queryClient = useQueryClient();
    const { showSnack } = useContext(SnackBarContext);

    return useMutation({
        mutationFn: updateCustomerUser,
        ...options,
        onSuccess: (uesr, ...rest) => {
            queryClient.invalidateQueries({ queryKey: userKey });
            options?.onSuccess?.(uesr, ...rest);
            showSnack('Successfully updated customer user', 'success');
        },
        onError: () => {
            showSnack('There was an unexpected error, please try again', 'error');
        },
    });
};

async function deleteCustomerUser(id: string): Promise<void> {
    const response = await api.delete(`/v1/customer/user/${id}`);
    return response.data;
}

export const useDeleteCustomerUser = (
    options?: Omit<UseMutationOptions<void, Error, string>, 'mutationFn'>,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteCustomerUser,
        ...options,
        onSuccess: (...rest) => {
            queryClient.invalidateQueries({ queryKey: userKey });
            options?.onSuccess?.(...rest);
        },
    });
};