import { useState, MouseEvent } from 'react';
import { Menu, MenuItem, Fab, darken } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from './ConfirmationDialog';

interface TableActionsDropdownProps {
  onEdit: () => void;
  onDelete: () => void;
  dialogTitle: string;
  dialogMessage: JSX.Element;
}

export default function TableActionsDropdown({
  onEdit,
  onDelete,
  dialogTitle,
  dialogMessage,
}: TableActionsDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    handleMenuClose();
    onEdit();
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Fab
        color="primary"
        size="small"
        onClick={handleMenuOpen}
        sx={{
          mr: 2,
          '&:hover': {
            backgroundColor: darken('#F6AE2D', 0.2),
          },
        }}
      >
        <MoreVertIcon />
      </Fab>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ boxShadow: 'none' }}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>

      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDialog}
        title={dialogTitle}
        message={dialogMessage}
      />
    </>
  );
}
