/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  styled,
  Divider,
  IconButton,
  Typography,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import Formik from '@/components/Formik';
import { FormikHelpers } from 'formik';
import SaveIcon from '@/components/icons/SaveIcon';
import { PTextField, SnackBarContext } from 'pragmatic-ui';
import { createSuperAdminSchema } from '@/lib/validation/admin';
import { addCustomerCreateTeamSchema } from '@/lib/validation/team';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthUser } from '@/hooks/auth/useAuth';
import { useGetVehiclesByCompany } from '@/hooks/customer/useVehicles';
import type { MembershipRole } from '@/types/types';
import { permissions, customerAvailableRoles } from '@/constants';
import InputError from '@/components/InputError';
import { useCreateCustomerUser } from '@/hooks/customer/useUsers';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiChip-root': {
    color: '#F6AE2D',
    border: '1px solid #F6AE2D',
    background: 'transparent',
    '& .MuiSvgIcon-root': {
      color: '#F6AE2D !important',
    },
  },
});

interface Props {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CustomerCreateTeamForm({ setDrawerOpen }: Props) {
  const navigate = useNavigate();
  const { showSnack } = useContext(SnackBarContext);
  const roles = customerAvailableRoles.map((role) => role.name);
  const { data: authUser } = useAuthUser();
  const company = authUser?.currentCompany || '0';
  const { data: vehiclesByCompany = [] } = useGetVehiclesByCompany(
    String(company),
  );
  const createCustomerUser = useCreateCustomerUser();

  const vehicleOptionsAll = vehiclesByCompany.map((vehicle) => ({
    id: vehicle._id,
    name: vehicle.rego,
  }));
  const [vehicleOptions, setVehicleOptions] = useState<{ id: string; name: string }[]>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    setVehicleOptions(
      vehiclesByCompany.map((vehicle) => ({
        id: vehicle._id,
        name: vehicle.rego,
      })),
    );
  }, [vehiclesByCompany]);

  const handleSubmit = async (
    values: any,
    { resetForm }: FormikHelpers<any>,
  ) => {
    const formData = new FormData();

    const selectedRole = customerAvailableRoles.find(
      (role) => role.name === values.role,
    );
    if (!selectedRole) {
      showSnack('Error creating customer user', 'error');
      return;
    }
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('role', selectedRole.key);
    formData.append('mobile', values.mobile);
    formData.append('vehicles', values.vehicles);
    formData.append('currentCompany', String(company));

    createCustomerUser.mutate(formData, {
      onSuccess: () => {
        setSelectedVehicles([]);
        resetForm();
      },
    });
  };

  const formikProps = {
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      vehicles: [],
    },
    validationSchema: addCustomerCreateTeamSchema,
    onSubmit: handleSubmit,
  };

  const resetForm = () => {
    formikProps.initialValues = {
      name: '',
      email: '',
      mobile: '',
      vehicles: [],
    };
  };

  return (
    <>
      <Formik {...formikProps}>
        {({ handleSubmit, setFieldValue, errors }: any) => (
          <StyledForm onSubmit={handleSubmit}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={2}
            >
              <Typography variant="body2">Create New Account</Typography>
              <Box display="flex" justifyContent="flex-end" sx={{}}>
                <Button
                  sx={{ mr: 1 }}
                  type="submit"
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  INVITE
                </Button>
                <IconButton
                  size="small"
                  onClick={() => {
                    resetForm();
                    setDrawerOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box mt={2} ml={5} mr={5}>
              <Grid container>
                <Grid item xs={12}>
                  <PTextField
                    name="role"
                    label="Account Type"
                    variant="standard"
                    className="config-text-fields"
                    fullWidth={true}
                    sx={{
                      mr: 4,
                      mt: 1,
                      minWidth: '150px',
                    }}
                    InputProps={{
                      disableUnderline: false,
                    }}
                    select
                    SelectProps={{
                      multiple: false,
                      sx: {
                        '& .MuiSvgIcon-root': {
                          color: '#F6AE2D',
                        },
                      },
                    }}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={`role-${role}`}
                        value={role as MembershipRole}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </PTextField>
                  <InputError message={errors.role} sx={{ mt: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <PTextField
                    variant="standard"
                    name="name"
                    placeholder="Name"
                    InputProps={{
                      disableUnderline: false,
                      sx: { fontSize: '15px' },
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <PTextField
                    variant="standard"
                    name="email"
                    type="email"
                    placeholder="Email"
                    InputProps={{
                      disableUnderline: false,
                      sx: { fontSize: '15px' },
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box component="div">
                    <PTextField
                      variant="standard"
                      name="mobile"
                      type="mobile"
                      placeholder="Mobile #"
                      InputProps={{
                        disableUnderline: false,
                        sx: { fontSize: '15px' },
                      }}
                      fullWidth
                    />
                    <InputError message={errors.mobile} sx={{ mt: 1 }} />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <StyledAutocomplete
                    id="combo-box-vehicles"
                    options={vehicleOptions}
                    defaultValue={[]}
                    value={selectedVehicles}
                    multiple
                    fullWidth
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }

                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="standard" placeholder="Assign Vehicles" />
                    )}
                    onChange={(_, value: any) => {
                      setSelectedVehicles(value);
                      const selectedIds = value.map((v) => v.id);
                      setFieldValue('vehicles', selectedIds);
                      setVehicleOptions(
                        vehicleOptionsAll.filter(
                          (option) => !selectedIds.includes(option.id),
                        ),
                      );
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <PTextField
                    name="vehicles"
                    label="Assign Vehicles"
                    placeholder="Assign Vehicles"
                    variant="standard"
                    className="config-text-fields"
                    fullWidth={true}
                    sx={{
                      mr: 4,
                      mt: 1,
                      minWidth: '150px',
                    }}
                    InputProps={{
                      disableUnderline: false,
                    }}
                    select
                    SelectProps={{
                      multiple: true,
                      sx: {
                        '& .MuiSvgIcon-root': {
                          color: '#F6AE2D',
                        },
                      },
                    }}
                  >
                    {vehicles.map((vehicle) => (
                      <MenuItem key={`role-${vehicle._id}`} value={vehicle._id}>
                        {vehicle.rego}
                      </MenuItem>
                    ))}
                  </PTextField>
                  <InputError message={errors.role} sx={{ mt: 1 }} />
                </Grid> */}
              </Grid>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}
