import { Box } from '@mui/material';
import CustomerTeamTable from '@/features/teams/CustomerTeamTable';

export default function Teams() {
  return (
    <Box px={0.5}>
      <CustomerTeamTable/>
    </Box>
  );
}
