import { Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid-pro';
import CustomDataGrid from '@/components/CustomDataGrid';
import { useGetConfigurationsByCompany } from '@/hooks/customer/useConfigurations';
import { useGetVehiclesByCompany } from '@/hooks/customer/useVehicles';
import { useCompanies } from '@/hooks/admin/useCompanies';
import { useMemo, useState } from 'react';
import { isDateExpired, isDateExpiring } from '@/lib/dates';
import { useAuthUser } from '@/hooks/auth/useAuth';
import PdfNormalIcon from '@/components/icons/PdfNormalIcon';

export default function CustomerPermitTable() {
  const { data: authUser } = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const permitStatus = searchParams.get('permitStatus');
  const company = authUser?.currentCompany || '0';
  const { data: configurations = [] } = useGetConfigurationsByCompany(String(company));
  const { data: vehicles = [] } = useGetVehiclesByCompany(String(company));
  const { data: companies = [] } = useCompanies();

  const [filterStatus, setFilterStatus] = useState(permitStatus);

  // useEffect(() => {
  //   if (!filterStatus) {
  //     navigate(location.pathname, { replace: true });
  //   }
  // }, [filterStatus, navigate, location.pathname]);

  const initialState = useMemo(() => {
    const state: any = {
      pagination: { paginationModel: { pageSize: 10 } },
    };

    if (permitStatus) {
      state.filter = {
        filterModel: {
          items: [
            {
              field: 'status',
              operatorValue: 'equals',
              value: permitStatus,
            },
          ],
        },
      };
    }
    return state;
  }, [permitStatus, filterStatus]);

  const dataPermits = configurations.flatMap((config) => {
    const companyName =
      companies.find(({ _id }) => _id === config.company)?.name ?? '';
    const vehicleRego =
      vehicles.find(({ _id }) => _id === config.vehicle)?.rego ?? '';

    return config.permits.map((permit) => {
      return {
        ...permit,
        name: config.name,
        companyName,
        vehicleRego,
        configurationId: config._id,
      };
    });
  });

  const handleCellClick = (params: any) => {
    navigate(
      `/admin/configurations/edit/${params.row.configurationId}/${params.row._id}`,
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Configuration',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'vehicleRego',
      headerName: 'Vehicle',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'rca',
      headerName: 'RCA',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'issueDate',
      headerName: 'Issue',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'edit',
      headerName: 'View',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => (
        <Box display={'flex'} flexDirection={'row'}>
          {params.row.uploads.map((upload: any, index: number) => {
            if (upload.file.path.endsWith('.pdf')) {
              return (
                <Button
                  component="a"
                  href={upload.file.signedUrl}
                  color="primary"
                  size="small"
                  aria-label=""
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mr: 0,
                    width: '60px',
                    height: '70px',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#FFFFFF',
                      fontSize: '0.8rem',
                      zIndex: 1,
                    }}
                  >
                    PDF
                  </Box>
                  <PdfNormalIcon />
                </Button>
              );
            }
          })}
        </Box>
      ),
    },
  ];

  const getRowClassName = (params: any) => {
    const expiryDate = new Date(params.row.expiryDate);

    if (isDateExpired(expiryDate)) {
      return 'row-expired';
    }

    if (isDateExpiring(expiryDate)) {
      return 'row-expiring';
    }

    return 'row-valid';
  };

  return (
    <Box>
      <CustomDataGrid
        rows={dataPermits}
        columns={columns}
        getRowId={(row) => row._id}
        //hideFooter={true}
        disableRowSelectionOnClick={true}
        initialState={initialState}
        pagination={true}
        getRowClassName={getRowClassName}
        onFilterModelChange={(model) => {
          if (model.items.length === 0) {
            setFilterStatus(null);
          }
          return model;
        }}
      />
    </Box>
  );
}
