import { useContext, useMemo } from 'react';
import UserContext from '@/context/user';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, ListItemButton, ListItemIcon } from '@mui/material';
import { SideBar as PSideBar } from 'pragmatic-ui';
import ApplicationLogo from '@/components/ApplicationLogo';
import DashboardIcon from '@/components/icons/DashboardIcon';
import TeamIcon from '@/components/icons/TeamIcon';
import LogoutIcon from '@/components/icons/LogoutIcon';
import VehicleIcon from '@/components/icons/VehicleIcon';
import PermitsIcon from '@/components/icons/PermitsIcon';
import { useLogout } from '@/hooks/auth/useAuth';

interface SideBarProps {
  onOpen: (openedState: boolean) => void;
  selectedMenuKey: string;
}

export default function SidebarAdmin({ onOpen, selectedMenuKey }: SideBarProps) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const callback = () =>
    navigate('/admin/login', {
      replace: true,
    });
  const logout = useLogout({ callback });
  const handleProfileRedirect = () => navigate('/admin/profile');

  const handleSideBarChange = (open: boolean) => {
    onOpen(open);
  };

  const navItems = useMemo(() => {
    const handleLogout = () => logout.mutate({});
    return [
      {
        text: 'Application',
        key: 'Application',
        icon: <ApplicationLogo />,
      },
      {
        text: 'Dashboard',
        key: 'Dashboard',
        icon: <DashboardIcon />,
        onClick: () => navigate('/admin/dashboard'),
      },
      {
        text: 'Vehicles',
        key: 'Vehicles',
        icon: <VehicleIcon />,
        onClick: () => navigate('/admin/vehicles'),
      },
      {
        text: 'Permits',
        key: 'Permits',
        icon: <PermitsIcon />,
        onClick: () => navigate('/admin/permits'),
      },
      {
        text: 'Team',
        key: 'Team',
        icon: <TeamIcon />,
        divider: true,
        onClick: () => navigate('/admin/teams'),
      },
      // {
      //   text: 'Team Create',
      //   key: 'Team Create',
      //   icon: <TeamIcon />,
      //   divider: true,
      //   onClick: () => navigate('/teams/create'),
      // },
      {
        text: 'Logout',
        key: 'Logout',
        icon: <LogoutIcon />,
        onClick: () => handleLogout(),
      },
    ];
  }, [navigate, logout]);

  return (
    <PSideBar
      logoExpanded={<ApplicationLogo />}
      logoCollapsed={<ApplicationLogo />}
      items={navItems}
      childrenCollapsed={
        <CollapsedIcon
          profilePhotoUrl={user?.gravatar}
          handleProfileRedirect={handleProfileRedirect}
          selectedMenuKey={selectedMenuKey}
        />
      }
      onOpenChanged={handleSideBarChange}
      paperProps={{
        sx: {
          '& .MuiList-root': {
            margin: '14px',
          },
          '& .MuiList-root > .MuiButtonBase-root': {
            position: 'relative',
            bottom: '40px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '& .MuiList-root > .MuiButtonBase-root.MuiListItemButton-root:first-of-type .MuiBox-root svg':
            {
              position: 'relative',
              bottom: '50px',
              paddingBottom: '0',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          '& .MuiList-root > .MuiButtonBase-root.MuiListItemButton-root:first-of-type .MuiBox-root':
            {
              position: 'relative',
              top: '30px',
            },
          '& .MuiList-root > .MuiListItem-root': {
            position: 'relative !important',
            top: '-25px !important',
          },
          '& .MuiList-root > .MuiListItem-root:nth-of-type(2)': {
            marginTop: '10px !important',
          },
          '& .MuiList-root > .MuiDivider-root': {
            position: 'relative !important',
            bottom: '20px !important',
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.23)',
          },
        },
      }}
      selectedMenuKey={selectedMenuKey}
      defaultOpen={false}
      collapsible={false}
    ></PSideBar>
  );
}

const CollapsedIcon = ({
  profilePhotoUrl,
  handleProfileRedirect,
  selectedMenuKey,
}: {
  profilePhotoUrl: string | undefined;
  handleProfileRedirect: () => void;
  selectedMenuKey: string;
}) => {
  return (
    <Box py={3} mt={-3}>
      <ListItemButton
        onClick={handleProfileRedirect}
        sx={{
          ...(selectedMenuKey === 'Profile' && {
            bgcolor: 'rgba(255, 255, 255, 0.16)',
          }),
        }}
      >
        <ListItemIcon>
          <Avatar
            sx={{
              width: '3em',
              height: '3em',
            }}
            src={profilePhotoUrl}
          ></Avatar>
        </ListItemIcon>
      </ListItemButton>
    </Box>
  );
};
