import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  styled,
  Typography,
  IconButton,
  Box,
  Button,
  Grid,
  MenuItem,
  Divider,
  Autocomplete,
  TextField,
} from '@mui/material';
import InputError from '@/components/InputError';
import SectionBorder from '@/components/SectionBorder';
import { PTextField, SnackBarContext } from 'pragmatic-ui';
import type { MembershipRole } from '@/types/types';
import { permissions, customerAvailableRoles } from '@/constants';
import { updateTeamMemberSchema } from '@/lib/validation/team';
import { FormikHelpers } from 'formik';
import Formik from '@/components/Formik';
import type { TeamMemberUpdateInput } from '@/types/formInput';
import type { User } from '@/types/api';
import SaveIcon from '@/components/icons/SaveIcon';
import { useTeamMemberUpdate } from '@/hooks/admin/useTeam';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthUser } from '@/hooks/auth/useAuth';
import { useGetVehiclesByCompany } from '@/hooks/customer/useVehicles';
import {
  useUpdateCustomerUser,
  useDeleteCustomerUser,
} from '@/hooks/customer/useUsers';
import ConfirmationPopup from '@/components/ConfirmationPopup';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiChip-root': {
    color: '#F6AE2D',
    border: '1px solid #F6AE2D',
    background: 'transparent',
    '& .MuiSvgIcon-root': {
      color: '#F6AE2D !important',
    },
  },
});

interface Props {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  selectedUser: User | null;
}

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function CustomerTeamMemberManager({
  setDrawerOpen,
  selectedUser: user,
}: Props) {
  const roles = customerAvailableRoles.map((role) => role.name);
  const { showSnack } = useContext(SnackBarContext);
  const { data: authUser } = useAuthUser();
  const updateCustomerUser = useUpdateCustomerUser();
  const deleteCustomerUser = useDeleteCustomerUser();
  const company = authUser?.currentCompany || '0';
  const { data: vehiclesByCompany = [] } = useGetVehiclesByCompany(
    String(company),
  );
  const [confirmPopupDeleteAccount, setConfirmPopupDeleteAccount] =
    useState(false);

  const vehicleOptionsAll = vehiclesByCompany.map((vehicle) => ({
    id: vehicle._id,
    name: vehicle.rego,
  }));
  const [vehicleOptions, setVehicleOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectedVehicles, setSelectedVehicles] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    setVehicleOptions(
      vehiclesByCompany.map((vehicle) => ({
        id: vehicle._id,
        name: vehicle.rego,
      })),
    );
  }, [vehiclesByCompany]);

  useEffect(() => {
    if (user && user.vehicles) {
      const selectedIds = user.vehicles.map((v) => v._id);
      setSelectedVehicles(
        user.vehicles.map((v) => ({
          id: v._id,
          name: v.rego,
        })) || [],
      );
      setVehicleOptions(
        vehicleOptionsAll.filter((option) => !selectedIds.includes(option.id)),
      );
    }
  }, [user]);

  const handleSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    const formData = new FormData();

    const selectedRole = customerAvailableRoles.find(
      (role) => role.name === values.role,
    );
    if (!selectedRole) {
      showSnack('Error creating customer user', 'error');
      return;
    }

    formData.append('_id', user!._id);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('role', selectedRole.key);
    formData.append('mobile', values.mobile);
    formData.append('vehicles', values.vehicles);

    updateCustomerUser.mutate(formData, {
      onSuccess: () => {
        setSelectedVehicles([]);
        resetForm();
        setDrawerOpen(false);
      },
    });
  };

  const handleDeleteAction = () => {
    // Add your delete logic here
    deleteCustomerUser.mutate(user!._id, {
      onSuccess: () => {
        setConfirmPopupDeleteAccount(false);
        resetForm();
        setDrawerOpen(false);
      },
    });
  };

  const formikProps = {
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      role: user
        ? customerAvailableRoles.find((role) => role.key === user.type)?.name ||
          ''
        : '',
      mobile: user?.mobile || '',
      vehicles: user?.vehicles?.map((v) => v._id) || [],
    },
    validationSchema: updateTeamMemberSchema,
    onSubmit: handleSubmit,
  };

  const resetForm = () => {
    formikProps.initialValues = {
      name: user?.name || '',
      email: user?.email || '',
      role: '',
      mobile: user?.mobile || '',
      vehicles: user?.vehicles?.map((v) => v._id) || [],
    };
  };
  return (
    <>
      {permissions.canAddTeamMembers ? (
        <>
          <Formik {...formikProps}>
            {({ handleSubmit, setFieldValue, errors }) => (
              <StyledForm onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  m={2}
                >
                  <Typography variant="body2">Edit Account</Typography>
                  <Box display="flex" justifyContent="flex-end" sx={{}}>
                    <Button
                      sx={{ mr: 1 }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setConfirmPopupDeleteAccount(true);
                        // resetForm();
                        // setDrawerOpen(false);
                      }}
                    >
                      DELETE
                    </Button>
                    <Button
                      sx={{ mr: 1 }}
                      type="submit"
                      size="small"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                      SAVE
                    </Button>
                    <IconButton
                      size="small"
                      onClick={() => {
                        resetForm();
                        setDrawerOpen(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box mt={2} ml={5} mr={5}>
                  {/* <!-- Member Email --> */}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <PTextField
                        name="role"
                        label="Account Type"
                        variant="standard"
                        className="config-text-fields"
                        fullWidth={true}
                        sx={{
                          mr: 4,
                          mt: 1,
                          minWidth: '150px',
                        }}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        select
                        SelectProps={{
                          multiple: false,
                          sx: {
                            '& .MuiSvgIcon-root': {
                              color: '#F6AE2D',
                            },
                          },
                        }}
                      >
                        {roles.map((role) => (
                          <MenuItem
                            key={`role-${role}`}
                            value={role as MembershipRole}
                          >
                            {role}
                          </MenuItem>
                        ))}
                      </PTextField>
                      <InputError message={errors.role} sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="div">
                        <PTextField
                          variant="standard"
                          name="name"
                          type="name"
                          placeholder="Name"
                          InputProps={{
                            disableUnderline: false,
                            sx: { fontSize: '15px' },
                          }}
                          fullWidth
                        />
                        <InputError message={errors.name} sx={{ mt: 1 }} />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="div">
                        <PTextField
                          variant="standard"
                          name="email"
                          type="email"
                          disabled={true}
                          placeholder="Email"
                          InputProps={{
                            disableUnderline: false,
                            sx: { fontSize: '15px' },
                          }}
                          fullWidth
                        />
                        <InputError message={errors.email} sx={{ mt: 1 }} />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box component="div">
                        <PTextField
                          variant="standard"
                          name="mobile"
                          type="mobile"
                          placeholder="Mobile #"
                          InputProps={{
                            disableUnderline: false,
                            sx: { fontSize: '15px' },
                          }}
                          fullWidth
                        />
                        <InputError message={errors.email} sx={{ mt: 1 }} />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledAutocomplete
                        id="combo-box-vehicles"
                        options={vehicleOptions}
                        defaultValue={[]}
                        value={selectedVehicles}
                        multiple
                        fullWidth
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }

                          return option.name;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="standard"
                            placeholder="Assign Vehicles"
                          />
                        )}
                        onChange={(_, value: any) => {
                          setSelectedVehicles(value);
                          const selectedIds = value.map((v) => v.id);
                          setFieldValue('vehicles', selectedIds);
                          setVehicleOptions(
                            vehicleOptionsAll.filter(
                              (option) => !selectedIds.includes(option.id),
                            ),
                          );
                        }}
                        selectOnFocus
                        handleHomeEndKeys
                      />
                    </Grid>
                  </Grid>
                </Box>
              </StyledForm>
            )}
          </Formik>
        </>
      ) : null}
      <ConfirmationPopup
        open={confirmPopupDeleteAccount}
        title="Delete Account"
        message={<>Are you sure you want to delete this account?</>}
        handleConfirmClick={handleDeleteAction}
        handleCloseClick={() => {
          setConfirmPopupDeleteAccount(false);
        }}
      />
    </>
  );
}
