export default function PdfBigIcon() {
  return (
    <svg
      width="56"
      height="71"
      viewBox="0 0 56 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 1.75V16.75C31.5 20.8921 34.8579 24.25 39 24.25H54M54 22.1569V65.25C54 67.4591 52.2091 69.25 50 69.25H5.5C3.29086 69.25 1.5 67.4591 1.5 65.25V5.75C1.5 3.54086 3.29086 1.75 5.5 1.75H33.5931C34.654 1.75 35.6714 2.17143 36.4216 2.92157L52.8284 19.3284C53.5786 20.0786 54 21.096 54 22.1569Z"
        stroke="url(#paint0_linear_9406_32760)"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9406_32760"
          x1="56.7515"
          y1="18.0483"
          x2="-3.15137"
          y2="28.1921"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stop-color="#F26419" />
          <stop offset="0.62" stop-color="#CE4721" />
          <stop offset="0.82" stop-color="#BC3926" />
        </linearGradient>
      </defs>
    </svg>
  );
}
