export default function GMapIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.625 28.6636V6.78857"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.375 21.3716V31.4287"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.125 19.9135V27.6896C31.1264 28.3164 30.7258 28.8735 30.1312 29.0719C28.2392 29.7027 24.1872 31.0536 22.8292 31.5064C22.5319 31.6055 22.2104 31.6047 21.9136 31.5042L14.0862 28.8947C13.7868 28.7949 13.4632 28.7949 13.1638 28.8947L6.79455 31.0181C6.34983 31.1663 5.86095 31.0917 5.48065 30.8176C5.10035 30.5436 4.875 30.1034 4.875 29.6346V10.5988C4.875 9.97116 5.27665 9.41388 5.87212 9.21537L12.7026 6.93827C13.3013 6.73868 13.9486 6.73868 14.5474 6.93827L16.5417 7.60308"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9167 10.4341C20.9167 12.8236 23.5242 15.3761 25.0117 16.6383C25.5991 17.116 26.4411 17.116 27.0285 16.6383C28.5175 15.3761 31.125 12.8236 31.125 10.4341"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.0223 10.0697C26.2234 10.0705 26.3859 10.2341 26.3854 10.4352C26.3849 10.6364 26.2215 10.7991 26.0203 10.7988C25.8192 10.7986 25.6562 10.6354 25.6562 10.4343C25.6557 10.3372 25.6942 10.2439 25.763 10.1754C25.8318 10.1068 25.9252 10.0688 26.0223 10.0697"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9167 10.4342V10.4342C20.9167 7.61529 23.2019 5.33008 26.0208 5.33008V5.33008C28.8398 5.33008 31.125 7.61529 31.125 10.4342V10.4342"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
