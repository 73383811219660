import React, { useRef, useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import CustomDataGrid from '@/components/CustomDataGrid';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Fab,
  Divider,
  Menu,
  MenuItem,
  darken,
  InputLabel,
  styled,
  Link,
  CircularProgress,
  MenuProps,
  Modal,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthUser } from '@/hooks/auth/useAuth';
import {
  useDeleteVehicle,
  useGetVehiclesByCompany,
} from '@/hooks/customer/useVehicles';
import { useGetConfigurationsByCompany } from '@/hooks/customer/useConfigurations';
import type { Vehicle } from '@/types/api';
import PdfBigIcon from '@/components/icons/PdfBigIcon';
import PdfNormalIcon from '@/components/icons/PdfNormalIcon';
import { Configuration } from '@/types/api';

export default function CustomerVehicleDetail() {
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const { data: authUser } = useAuthUser();
  const company = authUser?.currentCompany || '0';
  const { vehicleId } = useParams();
  const { data: vehicles = [], refetch: refetchVehicles } =
    useGetVehiclesByCompany(String(company));
  const { data: configurations = [], refetch: refetchConfigurations } =
    useGetConfigurationsByCompany(String(company));
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  //const [configurationsForVehicle, setConfigurationsForVehicle] = useState<Configuration[]>([]);
  const [dataPermits, setDataPermits] = useState<any[]>([]);

  //const { companyId } = useParams();
  useEffect(() => {
    if (authUser) {
      //console.log('authUser-refetch', authUser, authUser?.currentCompany?._id);
      refetchConfigurations();
      refetchVehicles();
    }
  }, [authUser, refetchVehicles, refetchConfigurations]);

  useEffect(() => {
    const veh = vehicles.find(
      (vehicle) => vehicle._id === vehicleId,
    ) as Vehicle;
    setVehicle(veh);
  }, [vehicles, vehicleId]);

  useEffect(() => {
    const configs = configurations.filter(
      (conf) => conf.vehicle === vehicleId,
    ) as Configuration[];
    const permits = configs.flatMap((config) => {
      return config.permits.map((permit) => {
        return {
          ...permit,
          name: config.name,
        };
      });
    });
    setDataPermits(permits);
    console.log('dataPermits', permits);
  }, [configurations, vehicleId]);

  //   const handlePdfClick = (upload: any) => {
  //     console.log('handlePdfClick', upload);
  //     setSelectedPdf(upload.file.signedUrl);
  //   };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Configuration',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'rca',
      headerName: 'RCA',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'issueDate',
      headerName: 'Issue',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'edit',
      headerName: 'View',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => (
        <Box display={'flex'} flexDirection={'row'}>
          {params.row.uploads.map((upload: any, index: number) => {
            if (upload.file.path.endsWith('.pdf')) {
              return (
                <Button
                  component="a"
                  href={upload.file.signedUrl}
                  color="primary"
                  size="small"
                  aria-label=""
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mr: 0,
                    width: '60px',
                    height: '70px',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#FFFFFF',
                      fontSize: '0.8rem',
                      zIndex: 1,
                    }}
                  >
                    PDF
                  </Box>
                  <PdfNormalIcon />
                </Button>
              );
            }
          })}
        </Box>
      ),
    },
  ];

  return (
    <Box px={4}>
      <Typography variant="h6" sx={{ color: '#EAEAEA' }}>
        Handbooks & Certificates
      </Typography>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        {vehicle?.uploads?.map((upload, index) => (
          <Box
            key={'upload-' + index}
            display="flex"
            alignItems="flex-start"
            justifyContent="normal"
            py={2}
          >
            <Button
              component="a"
              href={upload.file.signedUrl}
              color="primary"
              size="small"
              aria-label=""
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mr: 2,
                width: '160px',
                height: '120px',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: '#F26419',
                  fontSize: '1.3rem',
                  zIndex: 1,
                }}
              >
                PDF
              </Box>
              <PdfBigIcon />
              <Typography variant="body1" sx={{ color: '#EAEAEA' }}>
                {upload.name}
              </Typography>
            </Button>
          </Box>
        )) || (
          <Typography variant="body1" sx={{ color: '#EAEAEA' }}>
            No handbooks
          </Typography>
        )}
      </Box>

      <Grid container mt={8} columnSpacing={4}>
        <Grid item xs={12}>
          <Divider component="div" sx={{ backgroundColor: '#BD3825' }} />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ color: '#EAEAEA' }}>
        Permits
      </Typography>

      <CustomDataGrid
        rows={dataPermits}
        columns={columns}
        getRowId={(row) => row._id}
        //hideFooter={true}
        disableRowSelectionOnClick={true}
        //initialState={initialState}
        pagination={true}
        filter={false}
        //getRowClassName={getRowClassName}
        // onFilterModelChange={(model) => {
        //   if (model.items.length === 0) {
        //     setFilterStatus(null);
        //   }
        //   return model;
        // }}
      />
    </Box>
  );
}
