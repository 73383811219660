import * as Yup from 'yup';

export const createTeamSchema = Yup.object().shape({
  name: Yup.string().required('Please provide a team name'),
});

export const addTeamMemberSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().min(6).required('Password is required'),
  confirm_password: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  role: Yup.string().required('Role is required'),
});

export const updateTeamMemberSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email().required('Email is required'),
  role: Yup.string().required('Role is required'),
});

export const addCustomerCreateTeamSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email().required('Email is required'),
  role: Yup.string().required('Role is required'),
});