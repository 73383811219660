import myAxios from '@/lib/api-client';
import { useState } from 'react';
import ActionMessage from '@/components/ActionMessage';
import FormSection from '@/components/FormSection';
import { updatePasswordSchema } from '@/lib/validation/userProfile';
import Formik from '@/components/Formik';
import { FormikProps } from 'formik';
import { PTextField } from 'pragmatic-ui';
import { Button, Grid } from '@mui/material';
import { PasswordInput } from '@/types/formInput';
import { usePasswordUpdate } from '@/hooks/auth/useAuth';
import { FormikHelpers } from 'formik';

interface FormValues {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export default function PUpdatePasswordForm() {
  const [success, setSuccess] = useState(false);
  const passwordUpdate = usePasswordUpdate();

  // TODO ON HOLD: set up correctly
  const handleSubmit = (
    values: PasswordInput, 
    { resetForm }: FormikHelpers<PasswordInput>,
  ) => {
    passwordUpdate.mutate(values);
    resetForm();
  };

  const formikProps = {
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: updatePasswordSchema,
    onSubmit: handleSubmit,
  };

  return (
    <Formik {...formikProps}>
      {({ handleSubmit, isValid, isSubmitting }: FormikProps<FormValues>) => (
        <FormSection
          onSubmit={handleSubmit}
          title={'Update Password'}
          description={
            'Ensure your account is using a long, random password to stay secure.'
          }
          renderActions={() => (
            <>
              <ActionMessage on={success} sx={{ mr: 1.5 }}>
                Saved.
              </ActionMessage>

              <Button
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Save
              </Button>
            </>
          )}
        >
          <Grid container spacing={3} pl={4} sx={{ pl: { sm: 0, xs: 0 } }}>
            {/* <!-- Current password --> */}
            <Grid item xs={12} sm={12}>
              <PTextField
                label="Current password"
                type="password"
                name="current_password"
                fullWidth
                autoComplete="current-password"
              />
            </Grid>

            {/* <!-- New password --> */}
            <Grid item xs={12} sm={12}>
              <PTextField
                label="New password"
                type="password"
                name="password"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>

            {/* <!-- New password confirmation --> */}
            <Grid item xs={12} sm={12}>
              <PTextField
                label="Confirm password"
                type="password"
                name="password_confirmation"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </FormSection>
      )}
    </Formik>
  );
}
