import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import CustomDataGrid from '@/components/CustomDataGrid';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import TrailerIcon from '@/components/icons/TrailerIcon';
import TractorIcon from '@/components/icons/TractorIcon';
import TableActionsDropdown from '@/components/TableActionsDropdown';
import { useDeleteVehicle, useVehicles } from '@/hooks/admin/useVehicles';
import { useCompanies } from '@/hooks/admin/useCompanies';
import { Company } from '@/types/api';

export default function VehiclesTable() {
  const { data: companies = [] } = useCompanies();
  const { data: vehicles = [] } = useVehicles();
  const deleteVehicle = useDeleteVehicle();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');

  const initialState = useMemo(() => {
    const state: any = {
      pagination: { paginationModel: { pageSize: 10 } },
    };

    if (companyId) {
      state.filter = {
        filterModel: {
          items: [
            {
              field: 'company',
              operatorValue: 'equals',
              value: companies.find((company) => company._id === companyId)
                ?.name,
            },
          ],
        },
      };
    }
    return state;
  }, [companyId, companies]);

  const handleCellClick = (params: any) => {
    navigate(`/admin/vehicles/${params.row._id}`);
  };

  const columns: readonly GridColDef[] = [
    {
      field: 'vehicleType',
      headerName: '',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value === 'Trailer') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
              }}
            >
              <TrailerIcon />
            </Box>
          );
        }

        if (params.value === 'Tractor') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
              }}
            >
              <TractorIcon />
            </Box>
          );
        }

        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%',
            }}
          >
            <MobileCraneIcon />
          </Box>
        );
      },
    },
    {
      field: 'rego',
      headerName: 'Rego No.',
      minWidth: 75,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Chip
            sx={{
              background: '#fff',
              borderRadius: '4px',
              width: '100px',
            }}
            label={
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#202D38',
                }}
              >
                {params.value.toUpperCase()}
              </Typography>
            }
          />
        </>
      ),
    },
    {
      field: 'fleetNumber',
      headerName: 'Fleet No.',
      minWidth: 175,
      flex: 1,
      valueGetter: (fleetNumber: string) => fleetNumber ?? '',
    },
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 150,
      flex: 1,
      valueGetter: (make: string) => make ?? '',
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 150,
      flex: 1,
      valueGetter: (model: string) => model ?? '',
    },
    {
      field: 'company',
      headerName: 'Client',
      minWidth: 300,
      flex: 1,
      valueGetter: (company: Company) => company.name,
    },
    {
      field: 'branch',
      headerName: 'Branch',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Actions',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <TableActionsDropdown
            onEdit={() => handleCellClick(params)}
            onDelete={() => deleteVehicle.mutate(params.row._id)}
            dialogTitle={'Delete vehicle'}
            dialogMessage={
              <>
                <Typography>
                  This vehicle will no longer be visible by all associated
                  members.
                </Typography>
                <Typography>
                  Do you still wish to permanently delete this vehicle?
                </Typography>
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      rows={vehicles}
      columns={columns}
      getRowId={(row) => row._id}
      disableRowSelectionOnClick={true}
      initialState={initialState}
      pagination={true}
    />
  );
}
