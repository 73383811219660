export default function ManIcon() {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 19C17.375 17.6044 17.375 16.9067 17.2028 16.3389C16.815 15.0605 15.8145 14.06 14.5361 13.6722C13.9683 13.5 13.2706 13.5 11.875 13.5H6.875C5.47944 13.5 4.78165 13.5 4.21386 13.6722C2.93545 14.06 1.93504 15.0605 1.54724 16.3389C1.375 16.9067 1.375 17.6044 1.375 19M13.875 5.5C13.875 7.98528 11.8603 10 9.375 10C6.88972 10 4.875 7.98528 4.875 5.5C4.875 3.01472 6.88972 1 9.375 1C11.8603 1 13.875 3.01472 13.875 5.5Z"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
