import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '@/context/user';

function PublicRoute({ children }: { children: React.ReactNode }) {
  const { user = null } = useContext(UserContext);

  if (user && user._id && user.type === 'admin') {
    return <Navigate to="/admin/dashboard" replace />;
  }

  if (user && user._id && user.type === 'user') {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

export default PublicRoute;
