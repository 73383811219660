import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, darken, Fab, Typography } from '@mui/material';
import CustomDataGrid from '@/components/CustomDataGrid';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import TrailerIcon from '@/components/icons/TrailerIcon';
import TractorIcon from '@/components/icons/TractorIcon';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
import { useAuthUser } from '@/hooks/auth/useAuth';
import {
  useDeleteVehicle,
  useGetVehiclesByCompany,
} from '@/hooks/customer/useVehicles';
import { useGetConfigurationsByCompany } from '@/hooks/customer/useConfigurations';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCustomerUsersByCompany } from '@/hooks/customer/useUsers';

export default function CustomerVehiclesTable() {
  const { data: authUser } = useAuthUser();
  //const { data: companies = [] } = useCompanies();
  const company = authUser?.currentCompany || '0';
  const { data: vehicles = [], refetch: refetchVehicles } =
    useGetVehiclesByCompany(String(company));
  const { data: configurations = [], refetch: refetchConfigurations } =
    useGetConfigurationsByCompany(String(company));
  const { data: users = [], isLoading } = useCustomerUsersByCompany(String(company));

  const deleteVehicle = useDeleteVehicle();
  const navigate = useNavigate();

  //const { companyId } = useParams();
  useEffect(() => {
    if (authUser) {
      //console.log('authUser-refetch', authUser, authUser?.currentCompany?._id);
      refetchConfigurations();
      refetchVehicles();
    }
  }, [authUser, refetchVehicles, refetchConfigurations]);

  const vehiclesCalculated = useMemo(() => {
    return vehicles.map((vehicle) => {
      const permitTotal = configurations.filter(
        (config) => config.vehicle === vehicle._id
      ).length;

      // const driversTotal = users.filter(
      //   (user) => user.vehicles && user.vehicles.some((v) => v._id === vehicle._id)
      // ).length;

      const driversNames = users
        .filter((user) => user.vehicles && user.vehicles.some((v) => v._id === vehicle._id))
        .map((user) => user.name)
        .join(', ');

      return { ...vehicle, permitTotal, driversNames };
    });
  }, [vehicles, configurations, users]);

  const handleCellClick = (params: any) => {
    navigate(`/vehicles/${params.row._id}`);
  };

  const columns: readonly GridColDef[] = [
    {
      field: 'vehicleType',
      headerName: '',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value === 'Trailer') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
              }}
            >
              <TrailerIcon />
            </Box>
          );
        }

        if (params.value === 'Tractor') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
              }}
            >
              <TractorIcon />
            </Box>
          );
        }

        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%',
            }}
          >
            <MobileCraneIcon />
          </Box>
        );
      },
    },
    {
      field: 'rego',
      headerName: 'Rego No.',
      minWidth: 75,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Chip
            sx={{
              background: '#fff',
              borderRadius: '4px',
              width: '100px',
            }}
            label={
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#202D38',
                }}
              >
                {params.value.toUpperCase()}
              </Typography>
            }
          />
        </>
      ),
    },
    {
      field: 'fleetNumber',
      headerName: 'Fleet No.',
      minWidth: 175,
      flex: 1,
      valueGetter: (fleetNumber: string) => fleetNumber ?? '',
    },
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 150,
      flex: 1,
      valueGetter: (make: string) => make ?? '',
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 150,
      flex: 1,
      valueGetter: (model: string) => model ?? '',
    },
    {
      field: 'permitTotal',
      headerName: 'Permit Total',
      minWidth: 40,
      flex: 1,
      //type: 'number',
    },
    {
      field: 'driversNames',
      headerName: 'Drivers',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '42px',
              width: '188px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={params.value}
          >
            <Typography variant="body1" color="textPrimary" ml="5px">
              {params.value}
            </Typography>
          </Box>
        </Box>
      ),
      //type: 'number',
      //valueGetter: () => 0,
    },
    {
      field: '',
      headerName: '',
      minWidth: 50,
      width: 50,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            onClick={() => handleCellClick(params)}
          >
            <ArrowRightIcon />
          </Fab>
        </>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={vehiclesCalculated}
      columns={columns}
      getRowId={(row) => row._id}
      //hideFooter={true}
      disableRowSelectionOnClick={true}
      //initialState={initialState}
      pagination={true}
      filter={true}
      search={true}
    />
  );
}
