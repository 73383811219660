import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';



export default function ErrorPage() {
  const location = useLocation();
  const error = location.state?.message;


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgba(26,32,44,1)',
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 24,
            color: '#a0aec0',
          }}
        >
          {error}
        </Typography>
      </Box>
    </Box>
  );
}
