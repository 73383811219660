import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Typography,
  Fab,
  darken,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import type { Nullable } from '@/types/types';
import type { User } from '@/types/api';
import CustomerTeamMemberManager from '@/features/teams/CustomerTeamMemberManager';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import CustomDataGrid from '@/components/CustomDataGrid';
import { useCustomerUsersByCompany } from '@/hooks/customer/useUsers';
import { useAuthUser } from '@/hooks/auth/useAuth';

const CustomerTeamTable = () => {
  const { data: authUser } = useAuthUser();
  const company = authUser?.currentCompany || '0';
  const { data: users, isLoading } = useCustomerUsersByCompany(String(company));
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [teamMemberBeingRemoved, setTeamMemberBeingRemoved] =
    useState<Nullable<User>>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  if (!users) return null;

  const handleEditButtonClicked = (user: User) => {
    //console.log('user', user);
    setSelectedUser(user);
    setDrawerOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'gravatar',
      headerName: 'Name',
      minWidth: 60,
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            component="div"
            display="flex"
            justifyContent="start"
            alignItems="center"
            height={"100%"}
          >
            <Avatar src={params.row.gravatar} />
            <Typography variant="body1" color="textPrimary" ml='5px'>
              {
                params.row.name
              }
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'MOBILE #',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Account Type',
      minWidth: 100,
      flex: 1,
      valueGetter: (val) =>
        String(val).charAt(0).toUpperCase() + String(val).slice(1),
    },
    {
      field: 'vehicles',
      headerName: 'Assigned Vehicles',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const vehicles = params.row.vehicles as { rego: string }[];
        if (Array.isArray(vehicles)) {
          return vehicles.map(vehicle => vehicle.rego).join(', ');
        }
        return '';
      }
    },
    {
      field: 'status',
      headerName: 'INVITE',
      minWidth: 50,
      width: 60,
      flex: 1,
      renderCell: (params) => {
        if(params.row.status === 'active') {
          return (<>Active</>);
        } else if (params.row.status === 'invited'){
          return (<>Resend</>);
        } else if (params.row.status === 'deleted'){
          return (<>Deleted</>);
        } else{
          return (<></>);
        }
      }
    },
    {
      field: 'actions',
      headerName: 'Edit',
      minWidth: 60,
      width: 60,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Fab
            color="primary"
            size="small"
            aria-label="edit"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            onClick={() => handleEditButtonClicked(params.row)}
          >
            <PencilOutlinedIcon />
          </Fab>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Box>
        <CustomDataGrid
          rows={users}
          getRowId={(user) => user._id}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={users.length}
          loading={isLoading}
          filter={false}
          search={false}
        />
      </Box>

      {/* Remove team member dialog */}
      <Dialog
        open={!!teamMemberBeingRemoved}
        onClose={() => setTeamMemberBeingRemoved(null)}
        maxWidth="xs"
      >
        <DialogTitle>Remove Team Member</DialogTitle>
        <DialogContent>
          Are you sure you would like to remove this person from the team?
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setTeamMemberBeingRemoved(null)}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            // onClick={removeTeamMember}
            disabled={isLoading}
            variant="contained"
            size="small"
            color="primary"
            sx={{ ml: 1 }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* User edit drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '30% !important',
            background: '#333C45',
            borderRadius: '12px 0px 0px 12px !important',
          },
        }}
      >
        <div role="presentation">
          {/* <Box component="div" m={3}>
            <Typography variant="body2">
              <ArrowBackIcon
                sx={{ cursor: 'pointer', mr: 3 }}
                onClick={() => setDrawerOpen(false)}
              />
              Edit Account
            </Typography>
          </Box> */}
          <Box>
            <CustomerTeamMemberManager
              setDrawerOpen={setDrawerOpen}
              selectedUser={selectedUser}
            />
          </Box>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CustomerTeamTable;
