import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
  useDeleteSavedRoute,
  useSavedRoutes,
} from '@/hooks/admin/useSavedRoutes';

import MapWithAutocomplete from '@/components/NewMapWithAutocomplete';
import { useConfigurations } from '@/hooks/admin/useConfigurations';
import ArrowLeftIcon from '@/components/icons/ArrowAltLeftIcon';
import { useVehicles } from '@/hooks/admin/useVehicles';
import DeleteIcon from '@mui/icons-material/Delete';

export default function SavedRoutes() {
  const navigate = useNavigate();
  const { companyId, configurationId, savedRouteId } = useParams();
  const { data: configurations } = useConfigurations();
  const { data: vehicles } = useVehicles();
  const { data: savedRoutes, isLoading } = useSavedRoutes();
  const [displayLeftBar, setDisplayLeftBar] = useState<boolean>(true);
  const [selectedSavedRouteId, setSelectedSavedRouteId] =
    useState<any>(savedRouteId);

  const deleteSavedRoute = useDeleteSavedRoute();

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (savedRoutes && !isLoading) {
  //       const routesForConfig = savedRoutes.filter(
  //         (route) => route.configuration === configurationId,
  //       );
  //       if (routesForConfig.length > 0) {
  //         requestAnimationFrame(() => {
  //           setSelectedSavedRoute(routesForConfig[0]);
  //         });
  //       }
  //     }
  //   }, 1000);
  // }, [isLoading]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const routesForConfiguration = savedRoutes?.filter(
    (route) => route.configuration === configurationId,
  );

  const configuration = configurations?.find((c) => c._id === configurationId);
  const vehicle = vehicles?.find((v) => v._id === configuration?.vehicle);
  const geojsonData = configuration?.permits.map((p) => p.route);

  const selectedRoute = savedRoutes?.find(
    (route) => route._id === savedRouteId,
  );

  const switchLeftBar = () => {
    setDisplayLeftBar((prev) => !prev);
  };

  const handleDelete = (id: string) => {
    deleteSavedRoute.mutate(id);
  };

  return (
    <Box>
      <Grid container spacing={0}>
        {displayLeftBar && (
          <Grid
            item
            xs={4}
            sx={{
              height: '100vh',
              overflowY: 'auto',
            }}
          >
            <Box px={1} py={1}>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ marginTop: '-10px' }}
              >
                <IconButton
                  onClick={() =>
                    navigate(
                      `/admin/companies/${companyId}/configurations/view`,
                    )
                  }
                >
                  <ArrowLeftIcon />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginLeft: '2px',
                  }}
                >
                  {vehicle?.rego}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    backgroundColor: '#444444',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '1px 10px',
                    marginLeft: '10px',
                  }}
                >
                  {configuration?.name}
                </Typography>
              </Box>

              {routesForConfiguration?.map((route: any) => (
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    marginTop: '10px',
                    marginLeft: '6px',
                    background: '#334657',
                    borderRadius: '6px',
                    border:
                      selectedSavedRouteId === route._id
                        ? '1.5px solid #F6AE2D'
                        : 'none',
                  }}
                  onClick={() =>
                    navigate(
                      `/admin/companies/${companyId}/configurations/${configurationId}/saved-routes/${route._id}`,
                    )
                  }
                  key={route._id}
                >
                  <Box
                    sx={{
                      padding: '10px 0 10px 10px',
                      width: '40%',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {/* STEVE THOMASON */}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {format(
                        new Date(route.updatedAt),
                        'hh:mma, dd-MMM-yyyy',
                      ).toUpperCase()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: '10px 0',
                      width: '30%',
                      marginLeft: '5px',
                    }}
                  >
                    <Typography sx={{ fontSize: '13px', textAlign: 'left' }}>
                      {route.start.name} - {route.end.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: '10px 0',
                      width: '10%',
                      marginLeft: '10%',
                    }}
                  >
                    <IconButton sx={{}} onClick={() => handleDelete(route._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={displayLeftBar ? 8 : 12}
          sx={{ height: '100vh', marginTop: '-20px' }}
        >
          {!isLoading ? (
            <MapWithAutocomplete
              company={String(companyId)}
              configuration={String(configurationId)}
              switchLeftBar={switchLeftBar}
              displayLeftBar={displayLeftBar}
              routeData={{
                geojsonData,
                ...selectedRoute,
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}
