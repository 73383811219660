import myAxios from '@/lib/api-client';
import Head from '@/components/Head';
import { forgotPasswordSchema } from '@/lib/validation/auth';
import { Box, Button, Container, Typography, styled } from '@mui/material';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo';
import Formik from '@/components/Formik';
import { PTextField } from 'pragmatic-ui';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

type ValuesProps = {
  email: string;
};

export default function ForgotPassword() {
  // TODO ON HOLD: set up correctly
  const handleSubmit = (values: ValuesProps) => {
    myAxios
      .post('/admin/user/password/email', values)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err: Error) => console.error(err));
  };

  const formikProps = {
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmit,
  };

  return (
    <Container component="main" maxWidth="xs">
      <Head title="Forgot Password" />

      <Box
        mt={40}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <AuthenticationCardLogo />

        <Box component="div" mt={6} mb={2} textAlign="center">
          <Typography variant="body1">
            Forgot your password? Just let us know your email address and we
            will email you a password reset link that will allow you to choose a
            new one.
          </Typography>
        </Box>

        <Formik {...formikProps}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ handleSubmit, isSubmitting, isValid }: any) => (
            <StyledForm onSubmit={handleSubmit}>
              <PTextField
                id="email"
                type="email"
                name="email"
                label="Email"
                autoComplete="email"
                fullWidth
                sx={{ mt: 1 }}
              />

              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="end"
                mt={4}
              >
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                >
                  Email Password Reset Link
                </Button>
              </Box>
            </StyledForm>
          )}
        </Formik>
      </Box>
    </Container>
  );
}
