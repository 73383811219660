/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/* eslint-disable no-extra-boolean-cast */
import { useContext, useEffect, useState } from 'react';
import myAxios from '@/lib/api-client';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  styled,
  Typography,
  Fab,
  Divider,
  MenuItem,
  darken,
  InputLabel,
  Autocomplete,
  TextField,
  createFilterOptions,
  Chip,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormikProps, Field, FieldArray, FormikHelpers } from 'formik';
import {
  FileDropZone,
  PTextField,
  CurrentFiles,
  PDatePicker,
  SnackBarContext,
} from 'pragmatic-ui';
import Formik from '@/components/Formik';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SaveIcon from '@/components/icons/SaveIcon';
import { createVehicleSchema } from '@/lib/validation/vehicles';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import TractorIcon from '@/components/icons/TractorIcon';
import TrailerIcon from '@/components/icons/TrailerIcon';
import { useCompanies } from '@/hooks/admin/useCompanies';
import { useCreateVehicle, useVehicles } from '@/hooks/admin/useVehicles';
import CloseIcon from '@mui/icons-material/Close';

type CurrentTab = 'vehicles' | 'permits';

type VehicleFormValues = {
  rego: string;
  fleetNumber: string;
  make: string;
  model: string;
  vehicleType: string;
  uploads: {
    name: string;
    description?: string;
  }[];
};

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiChip-root': {
    color: '#F6AE2D',
    border: '1px solid #F6AE2D',
    background: 'transparent',
    '& .MuiSvgIcon-root': {
      color: '#F6AE2D !important',
    },
  },
});

const FileRender = ({
  file,
  onRemoveFileClick,
}: {
  file: CurrentFiles;
  onStarClick: () => void;
  onRemoveFileClick: () => void;
  removeEntry?: boolean;
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="primary" sx={{ mr: 4 }}>
          {file.filename}
        </Typography>
        <Button onClick={onRemoveFileClick}>Remove File</Button>
      </Box>
      {/* <Box display="flex" justifyContent="center" alignItems={'center'}>

        {removeEntry ? (
          <Button onClick={() => console.log('removing entry')}>
            Delete Entry
          </Button>
        ) : null}
      </Box> */}
    </>
  );
};

const VehicleIconSwitcher = ({ vehicleType }: any) => {
  if (vehicleType === 'Tractor') {
    return <TractorIcon />;
  }

  if (vehicleType === 'Trailer') {
    return <TrailerIcon />;
  }

  return <MobileCraneIcon />;
};

function parseVehicleData(vehicle: any) {
  const { id, rego, make, model, company, vehicleType, branch } = vehicle;
  const formattedString = `${rego}, ${make}, ${model}`;

  return {
    id,
    company,
    text: formattedString,
    vehicleType,
    branch,
  };
}

const filter = createFilterOptions();

function integrateFilesAndReorganize(data: any): any {
  const { forms, company, branch, ...files } = data;
  const newData = { company, branch };

  let filesKey: any;
  forms.forEach((form: any) => {
    Object.assign(newData, form);

    if (form.uploads) {
      // If files exist for this form, add them to the uploads array
      form.uploads.forEach((upload: any, idx: number) => {
        filesKey = `files-${idx}`;
        if (!upload.file) {
          upload.file = files[filesKey];
        }
      });
    }

    // Remove the files key from the original files object
    if (files[filesKey]) {
      delete files[filesKey];
    }
  });

  return newData;
}

export default function Configurations() {
  const { data: companies = [], isLoading: isLoadingCompanies } =
    useCompanies();
  const { data: vehicles = [], isLoading: isLoadingVehicles } = useVehicles();
  const createVehicle = useCreateVehicle();
  const navigate = useNavigate();
  const { showSnack } = useContext(SnackBarContext);
  const [currentTab, setCurrentTab] = useState<CurrentTab>('vehicles');
  const [formattedVehicles, setFormattedVehicles] = useState<any[]>([]);
  const [vehicleName, setVehicleName] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const isLoading = isLoadingCompanies || isLoadingVehicles;

  useEffect(() => {
    if (selectedVehicle === null) {
      setVehicleName('');
    }
  }, [selectedVehicle]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  
  if(!Array.isArray(vehicles)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const vehicleOptions: any = vehicles.map((vehicle: any) =>
    parseVehicleData(vehicle),
  );

  const handleSubmit = async (
    values: any,
    { resetForm }: FormikHelpers<any>,
  ) => {
    const formData = new FormData();

    // Add non-file data to FormData
    formData.append('company', values.company);
    formData.append('branch', values.branch);
    formData.append('rego', values.forms[0].rego);
    formData.append('fleetNumber', values.forms[0].fleetNumber);
    formData.append('make', values.forms[0].make);
    formData.append('model', values.forms[0].model);
    formData.append('vehicleType', values.forms[0].vehicleType);
    formData.append('uploads', JSON.stringify(values.forms[0].uploads));

    values.forms?.[0].uploads.forEach((upload: any, uploadIndex: number) => {
      if (values[`files-${uploadIndex}`]?.length) {
        formData.append(
          upload.name,
          values[`files-${uploadIndex}`][0],
          values[`files-${uploadIndex}`][0].name,
        );
      }
    });

    try {
      const data = await createVehicle.mutateAsync(formData);
      //console.log(data);
      setTimeout(() => {
        navigate('/admin/vehicles/' + data._id, { replace: true });
      }, 300);
      //resetForm();
      //showSnack('Vehicle submitted: File upload in progress... ', 'info');
      //navigate('/vehicles');
    } catch (error) {
      // console.error('Error creating vehicle:', error);
      showSnack('Error creating vehicle', 'error');
    }
  };

  const handlePermitSubmit = (values: any) => {
    const finalObject = integrateFilesAndReorganize(values);
    try {
      myAxios
        .post('/admin/permits/store', finalObject)
        .then(({ data }) => {
          console.log('submitting a permit config...', data);
          showSnack('Successfully added new permit', 'success');
        })
        .catch((err: Error) => {
          console.error(err);
          showSnack('There was an unexpected error, please try again', 'error');
        });
    } catch (err) {
      console.error(err);
    }
  };

  const formikProps = {
    initialValues: {
      company: '',
      branch: '',
      forms: [
        {
          rego: '',
          fleetNumber: '',
          make: '',
          model: '',
          vehicleType: '',
          uploads: [
            {
              name: '',
              description: '',
            },
          ],
        },
      ],
    },
    validationSchema: createVehicleSchema,
    onSubmit: handleSubmit,
  };

  const permitFormikProps = {
    initialValues: {
      forms: [
        {
          company: '',
          configuration_name: '',
          vehicles: [],
          uploads: [
            {
              rca: '',
              type: '',
              issued: new Date(),
              expiry: new Date(),
            },
          ],
        },
      ],
    },
    onSubmit: handlePermitSubmit,
  };

  return (
    <Box px={4}>
      {currentTab === 'vehicles' ? (
        <Formik {...formikProps}>
          {({ handleSubmit, values }: FormikProps<any>) => {
            return (
              <>
                <StyledForm onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display={'flex'} alignItems="center">
                      <PTextField
                        name="company"
                        size="small"
                        variant="standard"
                        label="Client"
                        sx={{
                          mr: 4,
                          mt: 1,
                          minWidth: '150px',
                        }}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        className="config-text-fields"
                        select
                        SelectProps={{
                          multiple: false,
                          label: 'Clients',
                          sx: {
                            '& .MuiSvgIcon-root': {
                              color: '#F6AE2D',
                            },
                          },
                        }}
                      >
                        {companies.map((company) => (
                          <MenuItem
                            key={`client-${company._id}`}
                            value={company._id}
                          >
                            {company.name}
                          </MenuItem>
                        ))}
                      </PTextField>
                      <PTextField
                        name="branch"
                        label="Branch"
                        variant="standard"
                        className="config-text-fields"
                        sx={{
                          mr: 4,
                          mt: 1,
                          minWidth: '150px',
                        }}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        select
                        SelectProps={{
                          multiple: false,
                          sx: {
                            '& .MuiSvgIcon-root': {
                              color: '#F6AE2D',
                            },
                          },
                        }}
                      >
                        {values.company
                          ? companies
                              .find((company) => company._id === values.company)
                              ?.branches.map((branch: any, i: number) => (
                                <MenuItem
                                  key={`branch-${i}-${branch}`}
                                  value={branch}
                                >
                                  {branch}
                                </MenuItem>
                              ))
                          : [].map(() => (
                              <MenuItem key={`default`} value={''}></MenuItem>
                            ))}
                      </PTextField>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Button
                        size="small"
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          mr: 2,
                        }}
                        onClick={() => navigate('/admin/vehicles')}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<SaveIcon />}
                        sx={{ textTransform: 'uppercase', fontWeight: '700' }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                  {currentTab === 'vehicles' ? (
                    <>
                      <FieldArray name="forms">
                        {() => (
                          <>
                            {values.forms.map(
                              (form: VehicleFormValues, index: number) => (
                                <Box key={index} py={8}>
                                  <Grid container columnSpacing={8}>
                                    <Grid item xs={2.3}>
                                      <PTextField
                                        variant="standard"
                                        name={`forms.${index}.rego`}
                                        placeholder="Rego No."
                                        InputProps={{
                                          disableUnderline: false,
                                        }}
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={2.4}>
                                      <PTextField
                                        variant="standard"
                                        name={`forms.${index}.fleetNumber`}
                                        placeholder="Fleet No."
                                        fullWidth
                                        InputProps={{
                                          disableUnderline: false,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2.4}>
                                      <PTextField
                                        variant="standard"
                                        name={`forms.${index}.make`}
                                        placeholder="Make"
                                        InputProps={{
                                          disableUnderline: false,
                                        }}
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={2.4}>
                                      <PTextField
                                        variant="standard"
                                        name={`forms.${index}.model`}
                                        placeholder="Model"
                                        InputProps={{
                                          disableUnderline: false,
                                        }}
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={2.4}>
                                      <PTextField
                                        variant="standard"
                                        name={`forms.${index}.vehicleType`}
                                        placeholder="Vehicle Type"
                                        InputProps={{
                                          disableUnderline: false,
                                        }}
                                        select
                                        SelectProps={{
                                          multiple: false,
                                          displayEmpty: true,
                                          placeholder: 'Vehicle Type',
                                          renderValue: (value: any) => {
                                            if (!value) {
                                              return (
                                                <Typography
                                                  sx={{
                                                    color: 'rgb(117, 117, 117)',
                                                  }}
                                                >
                                                  Vehicle Type
                                                </Typography>
                                              );
                                            }

                                            return <>{value}</>;
                                          },
                                          sx: {
                                            '& .MuiSvgIcon-root': {
                                              color: '#F6AE2D',
                                            },
                                          },
                                        }}
                                        fullWidth
                                      >
                                        {[
                                          'Mobile Crane',
                                          'Trailer',
                                          'Tractor',
                                        ].map((vehicle: string, i) => (
                                          <MenuItem
                                            key={`vehicle-${i}`}
                                            value={vehicle}
                                          >
                                            {vehicle}
                                          </MenuItem>
                                        ))}
                                      </PTextField>
                                    </Grid>

                                    <FieldArray name={`forms.${index}.uploads`}>
                                      {({
                                        remove: removeEntireRow,
                                        push,
                                      }: any) => (
                                        <>
                                          <Grid item xs={9} mt={4}>
                                            <Typography
                                              variant="h6"
                                              sx={{ color: '#EAEAEA' }}
                                            >
                                              Supporting documents
                                            </Typography>
                                            <Paper
                                              component="div"
                                              sx={{
                                                py: 3,
                                                pl: 4,
                                                pr: 1,
                                                mt: 4,
                                                borderRadius: '16px',
                                              }}
                                            >
                                              {form.uploads.map((_, idx) => (
                                                <Grid
                                                  container
                                                  columnSpacing={4}
                                                  key={`vehicle-uploads-${idx}`}
                                                >
                                                  <Grid item xs={3}>
                                                    <Field
                                                      name={`forms.${index}.uploads.${idx}.name`}
                                                    >
                                                      {({ field }: any) => (
                                                        <PTextField
                                                          {...field}
                                                          label="NAME"
                                                          placeholder="Add Name"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </Grid>

                                                  <Grid item xs={3}>
                                                    <Field
                                                      name={`forms.${index}.uploads.${idx}.description`}
                                                    >
                                                      {({ field }: any) => (
                                                        <PTextField
                                                          {...field}
                                                          label="DESCRIPTION"
                                                          placeholder="Description"
                                                          fullWidth
                                                        />
                                                      )}
                                                    </Field>
                                                  </Grid>

                                                  <Grid item xs={5}>
                                                    <InputLabel
                                                      sx={{ color: '#fff' }}
                                                    >
                                                      UPLOADS
                                                    </InputLabel>
                                                    <FileDropZone
                                                      name={`files-${idx}`}
                                                      maxFiles={1}
                                                      featured
                                                      fileFormat={{
                                                        'application/pdf': [
                                                          '.pdf',
                                                        ],
                                                      }}
                                                      renderTitle={(
                                                        openFilePicker,
                                                      ) => (
                                                        <>
                                                          <Typography
                                                            variant="caption"
                                                            sx={{
                                                              fontSize: '14px',
                                                              ml: -7,
                                                            }}
                                                            onClick={
                                                              openFilePicker
                                                            }
                                                          >
                                                            Drag and drop to
                                                            upload (max file
                                                            size: 50 MB)
                                                          </Typography>
                                                        </>
                                                      )}
                                                      renderButton={() => <></>}
                                                      renderFile={(
                                                        file,
                                                        onStarClick,
                                                        onRemoveFileClick,
                                                      ) => {
                                                        return (
                                                          <FileRender
                                                            file={file}
                                                            onStarClick={
                                                              onStarClick
                                                            }
                                                            onRemoveFileClick={
                                                              onRemoveFileClick
                                                            }
                                                          />
                                                        );
                                                      }}
                                                      containerSx={{
                                                        background:
                                                          'transparent',
                                                        px: 0,
                                                        mx: 0,
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={1}
                                                    container
                                                    display="flex"
                                                    direction={'column'}
                                                    justifyContent={'center'}
                                                  >
                                                    <Fab
                                                      onClick={() => {
                                                        removeEntireRow(idx);
                                                      }}
                                                      color="secondary"
                                                      size="small"
                                                      sx={{
                                                        mr: 2,
                                                        '&:hover': {
                                                          backgroundColor:
                                                            darken(
                                                              '#F6AE2D',
                                                              0.2,
                                                            ),
                                                        },
                                                      }}
                                                    >
                                                      <CloseIcon />
                                                    </Fab>
                                                  </Grid>
                                                </Grid>
                                              ))}
                                            </Paper>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            container
                                            display="flex"
                                            direction={'column'}
                                            justifyContent={'flex-end'}
                                          >
                                            <Box
                                              display="flex"
                                              justifyContent="flex-start"
                                              alignContent="center"
                                              alignItems="center"
                                            >
                                              <Fab
                                                color="primary"
                                                size="small"
                                                sx={{
                                                  mr: 2,
                                                  '&:hover': {
                                                    backgroundColor: darken(
                                                      '#F6AE2D',
                                                      0.2,
                                                    ),
                                                  },
                                                }}
                                                onClick={() =>
                                                  push({
                                                    name: '',
                                                    description: '',
                                                  })
                                                }
                                              >
                                                <AddIcon />
                                              </Fab>
                                              <Typography
                                                sx={{ color: '#F6AE2D' }}
                                              >
                                                Add Another Document
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </>
                                      )}
                                    </FieldArray>
                                  </Grid>

                                  <Grid container mt={8} columnSpacing={4}>
                                    <Grid item xs={12}>
                                      <Divider
                                        component="div"
                                        sx={{ backgroundColor: '#BD3825' }}
                                      />
                                    </Grid>
                                    {/* {index === values.forms.length - 1 ? (
                                      <Grid item xs={1.5}>
                                        <Box
                                          display="flex"
                                          justifyContent="flex-start"
                                          alignContent="center"
                                          alignItems="center"
                                          mt={-3}
                                        >
                                          <Fab
                                            color="primary"
                                            size="small"
                                            sx={{
                                              mr: 2,
                                              '&:hover': {
                                                backgroundColor: darken(
                                                  '#F6AE2D',
                                                  0.2,
                                                ),
                                              },
                                            }}
                                            onClick={() => {
                                              push({
                                                rego: '',
                                                fleet_number: '',
                                                make: '',
                                                model: '',
                                                vehicle_type: '',
                                                uploads: [
                                                  {
                                                    name: '',
                                                    description: '',
                                                  },
                                                ],
                                              });
                                            }}
                                          >
                                            <AddIcon />
                                          </Fab>
                                          <Typography sx={{ color: '#F6AE2D' }}>
                                            Add New
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    ) : null} */}
                                  </Grid>
                                </Box>
                              ),
                            )}
                          </>
                        )}
                      </FieldArray>
                    </>
                  ) : null}
                </StyledForm>
              </>
            );
          }}
        </Formik>
      ) : null}

      {currentTab === 'permits' ? (
        <Formik {...permitFormikProps}>
          {({ handleSubmit, values, resetForm }: FormikProps<any>) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setFormattedVehicles([]);
            }, [values.branch, resetForm]);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setFormattedVehicles([]);
            }, [values.company, resetForm]);

            return (
              <>
                <StyledForm onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          setCurrentTab('vehicles');
                          setFormattedVehicles([]);
                          resetForm();
                        }}
                        variant={'outlined'}
                        sx={{
                          background: 'transparent',
                          borderColor:
                            'linear-gradient(258deg, #F26419, #CE4721, #BC3926) !important',
                          color: '#fff',
                          borderRadius: '20px',
                        }}
                      >
                        Vehicles
                      </Button>
                      <Button
                        onClick={() => setCurrentTab('permits')}
                        variant={
                          currentTab === 'permits' ? 'contained' : 'outlined'
                        }
                        size="medium"
                        sx={{
                          background:
                            currentTab === 'permits'
                              ? 'linear-gradient(258deg, #F26419, #CE4721, #BC3926)'
                              : 'transparent',
                          borderColor:
                            'linear-gradient(258deg, #F26419, #CE4721, #BC3926) !important',
                          color: '#fff',
                          borderRadius: '20px',
                        }}
                      >
                        Permits
                      </Button>
                    </ButtonGroup>
                    <Box display={'flex'} alignItems="center">
                      <PTextField
                        name="client"
                        label="Client"
                        variant="standard"
                        className="config-text-fields"
                        sx={{
                          mr: 4,
                          mt: 1,
                          minWidth: '150px',
                        }}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        select
                        SelectProps={{
                          multiple: false,
                          label: 'Vehicle Type',
                          placeholder: 'Vehicle Type',
                          sx: {
                            '& .MuiSvgIcon-root': {
                              color: '#F6AE2D',
                            },
                          },
                        }}
                      >
                        {companies.map((company) => (
                          <MenuItem
                            key={`client-${company._id}`}
                            value={company._id}
                          >
                            {company.name}
                          </MenuItem>
                        ))}
                      </PTextField>
                      <PTextField
                        name="branch"
                        variant="standard"
                        label="Branch"
                        className="config-text-fields"
                        sx={{
                          mr: 4,
                          mt: 1,
                          minWidth: '150px',
                        }}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        select
                        SelectProps={{
                          multiple: false,
                          label: 'Vehicle Type',
                          placeholder: 'Vehicle Type',
                          sx: {
                            '& .MuiSvgIcon-root': {
                              color: '#F6AE2D',
                            },
                          },
                        }}
                      >
                        {values.company
                          ? companies
                              .find((company) => company._id === values.company)
                              ?.branches.map((branch: any, i: number) => (
                                <MenuItem
                                  key={`branch-${i}-${branch.id}`}
                                  value={branch.name}
                                >
                                  {branch.name}
                                </MenuItem>
                              ))
                          : null}
                      </PTextField>
                      <Box display="flex" alignItems="center">
                        <Button
                          size="small"
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: '700',
                            mr: 2,
                          }}
                          onClick={() => navigate('/vehicles')}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<SaveIcon />}
                          sx={{ textTransform: 'uppercase', fontWeight: '700' }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <>
                    <FieldArray name="forms">
                      {() => (
                        <>
                          {values.forms.map(
                            (form: VehicleFormValues, index: number) => (
                              <Box py={8} key={index}>
                                <Grid container columnSpacing={8}>
                                  <Grid item xs={2.2}>
                                    <PTextField
                                      variant="standard"
                                      autoComplete="off"
                                      name={`forms.${index}.configuration_name`}
                                      placeholder="Configuration Name"
                                      InputProps={{
                                        disableUnderline: false,
                                      }}
                                      fullWidth
                                    />
                                  </Grid>

                                  <Grid item xs={5}>
                                    <FieldArray
                                      name="vehicles"
                                      render={() => (
                                        <StyledAutocomplete
                                          id="combo-box-demo"
                                          options={vehicleOptions.filter(
                                            (vh: any) =>
                                              vh.company === values.company &&
                                              vh.branch === values.branch,
                                          )}
                                          renderTags={(value, getTagProps) => {
                                            return value.map(
                                              (option: any, index) => {
                                                return (
                                                  <Chip
                                                    variant="outlined"
                                                    label={`${
                                                      option.text.split(',')[0]
                                                    }`}
                                                    {...getTagProps({ index })}
                                                  />
                                                );
                                              },
                                            );
                                          }}
                                          defaultValue={[]}
                                          value={formattedVehicles}
                                          multiple
                                          fullWidth
                                          getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                              return option;
                                            }

                                            return option.text;
                                          }}
                                          inputValue={vehicleName}
                                          renderInput={(params) => {
                                            return (
                                              <TextField
                                                {...params}
                                                sx={{
                                                  mt: formattedVehicles.length
                                                    ? -0.7
                                                    : 0,
                                                }}
                                                placeholder={
                                                  formattedVehicles.length
                                                    ? ''
                                                    : 'Select Vehicles'
                                                }
                                                variant="standard"
                                              />
                                            );
                                          }}
                                          onInputChange={(event, value) => {
                                            setVehicleName(value);
                                          }}
                                          onClose={(event, reason) => {
                                            if (reason === 'blur') {
                                              setSelectedVehicle(null);
                                            }
                                          }}
                                          onChange={(
                                            event,
                                            value: any,
                                            reason,
                                          ) => {
                                            switch (reason) {
                                              case 'clear':
                                                setFormattedVehicles([]);
                                                break;
                                              case 'removeOption':
                                                setFormattedVehicles(value);
                                                break;
                                              case 'selectOption':
                                                const lastItem =
                                                  value[value.length - 1];
                                                if (
                                                  typeof lastItem === 'string'
                                                ) {
                                                  setFormattedVehicles([]);
                                                  break;
                                                }
                                                if (!!lastItem.type) {
                                                  let maxId = 1;
                                                  for (const {
                                                    id,
                                                  } of formattedVehicles) {
                                                    if (id > maxId) {
                                                      maxId = id;
                                                    }
                                                  }

                                                  setFormattedVehicles([
                                                    ...vehicles,
                                                    {
                                                      id: maxId + 1,
                                                      name: lastItem.value.split(
                                                        ',',
                                                      )[0],
                                                    },
                                                  ]);

                                                  break;
                                                }
                                                const isExists =
                                                  formattedVehicles.some(
                                                    (tagItem: any) =>
                                                      tagItem.id ===
                                                      lastItem.id,
                                                  );

                                                if (isExists) {
                                                  setSelectedVehicle(null);
                                                } else {
                                                  setSelectedVehicle(lastItem);
                                                  setFormattedVehicles(value);
                                                }
                                                break;
                                              default:
                                                break;
                                            }
                                          }}
                                          freeSolo
                                          selectOnFocus
                                          handleHomeEndKeys
                                          filterOptions={(options, params) => {
                                            const filtered = filter(
                                              options,
                                              params,
                                            );

                                            // if (params.inputValue !== '') {
                                            //   filtered.push({
                                            //     type: OPTIONS_TYPE.NEW,
                                            //     value: params.inputValue,
                                            //     name: `Add "${params.inputValue}"`,
                                            //   });
                                            // }
                                            return filtered;
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={1}>
                                    <></>
                                  </Grid>

                                  <Grid item xs={3.8}>
                                    <Paper
                                      component="div"
                                      sx={{
                                        py: formattedVehicles.length ? 3 : 6,
                                        pl: 4,
                                        pr: 4,
                                        mt: 2,
                                        borderRadius: '16px',
                                      }}
                                    >
                                      {formattedVehicles.map((vehicle) => {
                                        return (
                                          <Box
                                            mr={4}
                                            display={'inline-flex'}
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                          >
                                            <VehicleIconSwitcher
                                              vehicleType={vehicle.vehicleType}
                                            />
                                          </Box>
                                        );
                                      })}
                                    </Paper>
                                  </Grid>

                                  <FieldArray name={`forms.${index}.uploads`}>
                                    {({ push }: any) => (
                                      <>
                                        <Grid item xs={9} mt={8}>
                                          <Typography
                                            variant="h6"
                                            sx={{ color: '#EAEAEA' }}
                                          >
                                            Attach Permits
                                          </Typography>
                                          <Paper
                                            component="div"
                                            sx={{
                                              py: 3,
                                              pl: 2,
                                              pr: 10,
                                              mt: 4,
                                              borderRadius: '16px',
                                            }}
                                          >
                                            {form.uploads.map((_, idx) => (
                                              <Grid
                                                container
                                                columnSpacing={4}
                                                key={`uploads-${idx}`}
                                              >
                                                <Grid item xs={2}>
                                                  <Field
                                                    name={`forms.${index}.uploads.${idx}.rca`}
                                                  >
                                                    {({ field }: any) => (
                                                      <PTextField
                                                        {...field}
                                                        label="RCA"
                                                        placeholder="SELECT"
                                                        fullWidth
                                                      />
                                                    )}
                                                  </Field>
                                                </Grid>

                                                <Grid item xs={2}>
                                                  <Field
                                                    name={`forms.${index}.uploads.${idx}.type`}
                                                  >
                                                    {({ field }: any) => (
                                                      <PTextField
                                                        {...field}
                                                        label="TYPE"
                                                        placeholder="Select"
                                                        fullWidth
                                                      />
                                                    )}
                                                  </Field>
                                                </Grid>

                                                <Grid item xs={2}>
                                                  <Field
                                                    name={`forms.${index}.uploads.${idx}.type`}
                                                  >
                                                    {() => (
                                                      <LocalizationProvider
                                                        dateAdapter={
                                                          AdapterDateFns
                                                        }
                                                      >
                                                        <PDatePicker
                                                          name="issued"
                                                          label="ISSUED"
                                                        />
                                                      </LocalizationProvider>
                                                    )}
                                                  </Field>
                                                </Grid>

                                                <Grid item xs={2}>
                                                  <Field
                                                    name={`forms.${index}.uploads.${idx}.type`}
                                                  >
                                                    {() => (
                                                      <LocalizationProvider
                                                        dateAdapter={
                                                          AdapterDateFns
                                                        }
                                                      >
                                                        <PDatePicker
                                                          name="issued"
                                                          label="EXPIRY"
                                                        />
                                                      </LocalizationProvider>
                                                    )}
                                                  </Field>
                                                </Grid>

                                                <Grid item xs={4}>
                                                  <InputLabel
                                                    sx={{ color: '#fff' }}
                                                  >
                                                    UPLOADS
                                                  </InputLabel>
                                                  <FileDropZone
                                                    name={`files-${idx}`}
                                                    maxFiles={20}
                                                    featured
                                                    fileFormat={{
                                                      'application/pdf': [
                                                        '.pdf',
                                                      ],
                                                      'application/json': [
                                                        '.json',
                                                        '.geojson',
                                                      ],
                                                    }}
                                                    renderTitle={() => (
                                                      <>
                                                        <Typography
                                                          variant="caption"
                                                          sx={{
                                                            fontSize: '14px',
                                                            ml: -7,
                                                          }}
                                                        >
                                                          Drag and drop to
                                                          upload (max file size:
                                                          50 MB)
                                                        </Typography>
                                                      </>
                                                    )}
                                                    renderButton={() => <></>}
                                                    renderFile={(
                                                      file,
                                                      onStarClick,
                                                      onRemoveFileClick,
                                                    ) => {
                                                      return (
                                                        <FileRender
                                                          file={file}
                                                          onStarClick={
                                                            onStarClick
                                                          }
                                                          onRemoveFileClick={
                                                            onRemoveFileClick
                                                          }
                                                        />
                                                      );
                                                    }}
                                                    containerSx={{
                                                      background: 'transparent',
                                                      px: 0,
                                                      mx: 0,
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            ))}
                                          </Paper>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sx={{
                                            mt: `${
                                              values.forms[index].uploads
                                                .length === 1
                                                ? 11
                                                : values.forms[index].uploads
                                                    .length * 8.75
                                            }%`,
                                            ml: 0,
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignContent="center"
                                            alignItems="center"
                                          >
                                            <Fab
                                              color="primary"
                                              size="small"
                                              sx={{
                                                mr: 2,
                                                '&:hover': {
                                                  backgroundColor: darken(
                                                    '#F6AE2D',
                                                    0.2,
                                                  ),
                                                },
                                              }}
                                              onClick={() =>
                                                push({
                                                  name: '',
                                                  description: '',
                                                })
                                              }
                                            >
                                              <AddIcon />
                                            </Fab>
                                            <Typography
                                              sx={{ color: '#F6AE2D' }}
                                            >
                                              Add Another Permit
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      </>
                                    )}
                                  </FieldArray>
                                </Grid>

                                <Grid container mt={8} columnSpacing={8}>
                                  <Grid item xs={12}>
                                    <Divider
                                      component="div"
                                      sx={{ backgroundColor: '#BD3825' }}
                                    />
                                  </Grid>
                                  {/* {index === values.forms.length - 1 ? (
                                    <Grid item xs={1.5}>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignContent="center"
                                        alignItems="center"
                                        mt={-3}
                                      >
                                        <Fab
                                          color="primary"
                                          size="small"
                                          sx={{
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: darken(
                                                '#F6AE2D',
                                                0.2,
                                              ),
                                            },
                                          }}
                                          onClick={() => {
                                            push({
                                              rego: '',
                                              fleet_number: '',
                                              make: '',
                                              model: '',
                                              vehicle_type: '',
                                              uploads: [
                                                {
                                                  name: '',
                                                  description: '',
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <AddIcon />
                                        </Fab>
                                        <Typography sx={{ color: '#F6AE2D' }}>
                                          Add New
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  ) : null} */}
                                </Grid>
                              </Box>
                            ),
                          )}
                        </>
                      )}
                    </FieldArray>
                  </>
                </StyledForm>
              </>
            );
          }}
        </Formik>
      ) : null}
    </Box>
  );
}
