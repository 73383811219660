import { Box } from '@mui/material';
import CustomerVehiclesTable from '@/features/vehicles/CustomerVehiclesTable';

export default function Vehicles() {
  return (
    <Box px={0.5}>
      <CustomerVehiclesTable/>
    </Box>
  );
}
