import * as Yup from 'yup';

export const createClientSchema = Yup.object().shape({
  company_name: Yup.string(),
  manager_name: Yup.string(),
  email: Yup.string().email(),
  regions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    }),
  ),
  branches: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    }),
  ),
});

export const updateClientSchema = Yup.object().shape({
  company_name: Yup.string(),
  manager_name: Yup.string(),
  regions: Yup.array(),
  branches: Yup.array(),
});
