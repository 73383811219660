import { Configuration } from '@/types/api';
import api from '@/lib/api-client';
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { SnackBarContext } from 'pragmatic-ui';

const configurationsKey = ['configurations'];

async function getConfigurations(): Promise<Configuration[]> {
  const response = await api.get(`/v1/customer/configurations`);
  return response.data;
}

export const useConfigurations = (
  options?: Omit<
    UseQueryOptions<Configuration[], Error, Configuration[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: configurationsKey,
    queryFn: getConfigurations,
    ...options,
  });

async function createConfiguration(payload: FormData): Promise<Configuration> {
  const response = await api.post('/v1/customer/configurations', payload);
  return response.data;
}

export const useCreateConfiguration = (
  options?: Omit<
    UseMutationOptions<Configuration, Error, FormData>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  const setConfiguration = useCallback(
    (newConfiguration: Configuration) => {
      queryClient.setQueryData<Configuration[]>(
        configurationsKey,
        (oldData) => {
          return oldData ? [...oldData, newConfiguration] : [newConfiguration];
        },
      );
    },
    [queryClient],
  );

  return useMutation({
    mutationFn: createConfiguration,
    ...options,
    onSuccess: (configuration, ...rest) => {
      setConfiguration(configuration);
      queryClient.invalidateQueries({ queryKey: configurationsKey });
      options?.onSuccess?.(configuration, ...rest);
      showSnack('Successfully added new configuration', 'success');
    },
    onError: () => {
      showSnack('There was an unexpected error, please try again', 'error');
    },
  });
};

async function updateConfiguration(
  updatedConfiguration: FormData,
): Promise<Configuration> {
  const configurationId = updatedConfiguration.get('_id');
  if (!configurationId) {
    throw new Error('Configuration ID is required');
  }

  const response = await api.put(
    `/v1/customer/configurations/${configurationId}`,
    updatedConfiguration,
  );

  return response.data;
}

export const useUpdateConfiguration = (
  _options?: Omit<
    UseMutationOptions<Configuration, Error, Partial<Configuration>>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  return useMutation({
    mutationFn: updateConfiguration,
    onError: (_err) => {
      showSnack(
        'There was an error updating the configuration. Please try again',
        'error',
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: configurationsKey });
      showSnack('Successfully updated the configuration', 'success');
    },
  });
};

async function deleteConfiguration(id: string) {
  const response = await api.delete(`/v1/customer/configurations/${id}`);
  return response.data;
}

export const useDeleteConfiguration = (
  options?: Omit<UseMutationOptions<void, Error, string>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteConfiguration,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: configurationsKey });
      options?.onSuccess?.(...args);
    },
  });
};


async function getConfigurationsByCompany (company: string): Promise<Configuration[]> {
  const response = await api.get(`/v1/customer/configurations/${company}`);
  return response.data;
};

export const useGetConfigurationsByCompany = (
  company: string,
  options?: Omit<
    UseQueryOptions<Configuration[], Error, Configuration[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<Configuration[], Error>({
    queryKey: [configurationsKey, company],
    queryFn: () => getConfigurationsByCompany(company),
    ...options,
  });

  