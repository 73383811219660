import React, { useState } from 'react';
import { Box, Button, Toolbar, Typography, styled } from '@mui/material';
import { pageRoutes } from '@/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import SidebarCustomer from '@/layouts/SidebarCustomer';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomerCreateTeamDrawer from '@/layouts/CustomerCreateTeamDrawer';
import CustomerTeamMemberManager from '@/features/teams/CustomerTeamMemberManager';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#202D38',
  color: '#fff',
  width: `calc(100% - 78px)`,
  marginLeft: '255px',
  padding: '10px 5px',
  fontSize: '20px',
  fontWeight: '500',
  ...(open && {
    marginLeft: '255px',
    width: `calc(100% - 258px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function matchRoute(pathname: string) {
  for (const route of pageRoutes) {
    // Convert the route path to a regular expression
    const regexPath = route.path.replace(/:[^\s/]+/g, '([^/]+)');
    const regex = new RegExp(`^${regexPath}$`);

    // Test the pathname against the regular expression
    if (regex.test(pathname)) {
      return route.name;
    }
  }
  return 'Not Found'; // Return a default value if no match is found
}

const AppLayoutCustomer = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routeName = matchRoute(pathname);
  const [open, setOpen] = useState(true);

  const toggleDrawer = (newState: boolean) => {
    setOpen(newState);
  };

  return (
    <Box display="flex">
      <AppBar position="absolute" open={open} elevation={0}>
        <Toolbar
          sx={{
            pr: 24,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, fontWeight: 500 }}
          >
            {routeName}
          </Typography>
          {/** Temporary special case to show the user invites drawer */}
          {pathname === '/teams' ? <CustomerCreateTeamDrawer /> : null}
          {/* {pathname === '/vehicles' ? (
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddOutlinedIcon />}
              onClick={() => {
                navigate('/vehicles/new');
              }}
              sx={{ textTransform: 'uppercase', fontWeight: '700' }}
            >
              Add New
            </Button>
          ) : null} */}

          {pathname === '/configurations' ? (
            <Box>
              {/* <Select
                label="Client"
                placeholder="Client"
                autoWidth
                size="small"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: '#F6AE2D',
                  },
                }}
              >
                <MenuItem>
                 Transport Specifications LTD
                </MenuItem>
              </Select> */}
            </Box>
          ) : null}

          {/* {pathname === '/teams' ? <CreateAdminDrawer /> : null} */}
          {/* {pathname === '/teams' ? <AddTeamMemberDrawer /> : null} */}
        </Toolbar>
      </AppBar>
      <SidebarCustomer onOpen={toggleDrawer} selectedMenuKey={routeName || ''} />
      <Box
        component="main"
        overflow="auto"
        width={open ? 'calc(100% - 225px)' : 'calc(100% - 72px)'}
        mt={15}
        pt={2}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppLayoutCustomer;
