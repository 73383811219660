/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/* eslint-disable no-extra-boolean-cast */

import { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import { FormikHelpers } from 'formik';
import MyFormik from '@/components/Formik';
import { createClientSchema } from '@/lib/validation/client';
import SaveIcon from '@/components/icons/SaveIcon';
import { FieldArray } from 'formik';
import type { CompanyInput } from '@/types/formInput';
import { useCreateCompany } from '@/hooks/admin/useCompanies';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiChip-root': {
    color: '#F6AE2D',
    border: '1px solid #F6AE2D',
    background: 'transparent',
    '& .MuiSvgIcon-root': {
      color: '#F6AE2D !important',
    },
  },
});

const OPTIONS_TYPE = {
  NEW: 'new',
};

export default function CreateClientForm({
  toggleDrawer,
}: {
  toggleDrawer: () => void;
}) {
  const [companyName, setCompanyName] = useState('');
  const [managerName, setManagerName] = useState('');
  const [email, setEmail] = useState('');

  const branchOptions: { id: number; name: string }[] = [];
  const [branches, setBranches] = useState<{ id: number; name: string }[]>([]);
  const [branchName, setBranchName] = useState('');
  const [selectedBranch, setSelectedBranch] = useState(null);

  const regionOptions: { id: number; name: string }[] = [];
  const [regions, setRegions] = useState<{ id: number; name: string }[]>([]);
  const [regionName, setRegionName] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);

  const createCompany = useCreateCompany();

  useEffect(() => {
    if (selectedBranch === null) {
      setBranchName('');
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedRegion === null) {
      setRegionName('');
    }
  }, [selectedRegion]);

  const handleSubmit = (
    _values: CompanyInput,
    { resetForm }: FormikHelpers<CompanyInput>,
  ) => {
    toggleDrawer();

    const payload = {
      name: companyName,
      managerName,
      email,
      regions: regions.map((region) => region.name),
      branches: branches.map((branch) => branch.name),
    };

    createCompany.mutate(payload);
    resetForm();
  };
  const formikProps = {
    initialValues: {
      name: companyName,
      managerName,
      email,
      regions,
      branches,
    },
    validationSchema: createClientSchema,
    onSubmit: handleSubmit,
  };

  const filter = createFilterOptions();

  return (
    <MyFormik<any> {...formikProps}>
      {({ handleSubmit, resetForm }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                name="name"
                placeholder="Company Name"
                InputProps={{
                  disableUnderline: false,
                  sx: { fontSize: '15px' },
                }}
                fullWidth
                onChange={(event) => setCompanyName(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                name="managerName"
                placeholder="Manager Name"
                InputProps={{
                  disableUnderline: false,
                  sx: { fontSize: '15px' },
                }}
                fullWidth
                onChange={(event) => setManagerName(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                name="email"
                placeholder="Email Address"
                InputProps={{
                  disableUnderline: false,
                  sx: { fontSize: '15px' },
                }}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldArray
                name="regions"
                render={() => (
                  <StyledAutocomplete
                    id="combo-box-demo"
                    options={regionOptions}
                    defaultValue={[]}
                    value={regions}
                    multiple
                    fullWidth
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }

                      return option.name;
                    }}
                    inputValue={regionName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Regions"
                        variant="standard"
                      />
                    )}
                    onInputChange={(_event, value) => {
                      setRegionName(value);
                    }}
                    onClose={(_event, reason) => {
                      if (reason === 'blur') {
                        setSelectedRegion(null);
                      }
                    }}
                    onChange={(_event, value: any, reason) => {
                      switch (reason) {
                        case 'clear':
                          setRegions([]);
                          break;
                        case 'removeOption':
                          setRegions(value);
                          break;
                        case 'selectOption':
                          const lastItem = value[value.length - 1];
                          if (typeof lastItem === 'string') {
                            setSelectedRegion(null);
                            break;
                          }
                          if (!!lastItem.type) {
                            let maxId = 1;
                            for (const { id } of branches) {
                              if (id > maxId) {
                                maxId = id;
                              }
                            }

                            setRegions([
                              ...regions,
                              { id: maxId + 1, name: lastItem.value },
                            ]);

                            break;
                          }
                          const isExists = regions.some(
                            (tagItem) => tagItem.id === lastItem.id,
                          );

                          if (isExists) {
                            setSelectedRegion(null);
                          } else {
                            setSelectedRegion(lastItem);
                            setRegions(value);
                          }
                          break;
                        default:
                          break;
                      }
                    }}
                    freeSolo
                    selectOnFocus
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          type: OPTIONS_TYPE.NEW,
                          value: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldArray
                name="branches"
                render={() => (
                  <StyledAutocomplete
                    id="combo-box-demo"
                    options={branchOptions}
                    defaultValue={[]}
                    value={branches}
                    multiple
                    fullWidth
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }

                      return option.name;
                    }}
                    inputValue={branchName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Branches"
                        variant="standard"
                      />
                    )}
                    onInputChange={(_event, value) => {
                      setBranchName(value);
                    }}
                    onClose={(_event, reason) => {
                      if (reason === 'blur') {
                        setSelectedBranch(null);
                      }
                    }}
                    onChange={(_event, value: any, reason) => {
                      switch (reason) {
                        case 'clear':
                          setBranches([]);
                          break;
                        case 'removeOption':
                          setBranches(value);
                          break;
                        case 'selectOption':
                          const lastItem = value[value.length - 1];
                          if (typeof lastItem === 'string') {
                            setSelectedBranch(null);
                            break;
                          }
                          if (!!lastItem.type) {
                            let maxId = 1;
                            for (const { id } of branches) {
                              if (id > maxId) {
                                maxId = id;
                              }
                            }

                            setBranches([
                              ...branches,
                              { id: maxId + 1, name: lastItem.value },
                            ]);

                            break;
                          }
                          const isExists = branches.some(
                            (tagItem) => tagItem.id === lastItem.id,
                          );
                          if (isExists) {
                            setSelectedBranch(null);
                          } else {
                            setSelectedBranch(lastItem);
                            setBranches(value);
                          }
                          break;
                        default:
                          break;
                      }
                    }}
                    freeSolo
                    selectOnFocus
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          type: OPTIONS_TYPE.NEW,
                          value: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ mt: 8 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={() => {
                resetForm();
                toggleDrawer();
              }}
            >
              CANCEL
            </Button>
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              SAVE
            </Button>
          </Box>
        </StyledForm>
      )}
    </MyFormik>
  );
}
