import { useNavigate, useParams } from 'react-router-dom';
import myAxios from '@/lib/api-client';
import Head from '@/components/Head';
import {
  Box,
  Button,
  Container,
  Link as MuiLink,
  Typography,
  styled,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo';
import { Formik, Form } from 'formik';
import { PTextField } from 'pragmatic-ui';
import { Link as RouterLink } from 'react-router-dom';
import { resetInputSchema } from '@/lib/validation/auth';
import { ResetPasswordInput } from '@/types/formInput';
import { useReset } from '@/hooks/auth/useAuth';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function ResetRoute() {
  // const navigate = useNavigate();
  const { resetToken } = useParams();
  const isFromEmail = !!resetToken; // false -> /reset, true -> /reset/:resetToken
  const reset = useReset();

  const initialValues: ResetPasswordInput = {
    resetToken: resetToken || '',
    password: '',
    password_confirmation: '',
  };

  const handleSubmit = (values: ResetPasswordInput) => {
    reset.mutate(values);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Head title="Reset Password" />
      <Card
        sx={{
          marginTop: 40,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '12px 12px 12px 12px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingX: '10%',
            flexGrow: 1,
            paddingTop: 10,
            mb: 2,
          }}
        >
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={resetInputSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid, isSubmitting }) => {
                return (
                  <Form style={{ marginTop: '40px', width: '100%' }}>
                    <PTextField
                      name="password"
                      label="Password"
                      type="password"
                      autoComplete="password"
                      variant="outlined"
                      fullWidth
                    />
                    <PTextField
                      name="password_confirmation"
                      label="Password (Confirm)"
                      type="password"
                      autoComplete="password"
                      variant="outlined"
                      fullWidth
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={!isValid || isSubmitting}
                      sx={{ mt: 2, mb: 2 }}
                    >
                      Reset Password & Sign In
                    </Button>
                  </Form>
                );
              }}
            </Formik>
            <Grid container>
              <Grid item>
                <MuiLink
                  component={RouterLink}
                  to={'/login'}
                  style={{ textDecorationColor: '#757575', color: 'inherit' }}
                >
                  <Typography color="text.secondary" fontWeight={400}>
                    {'Already have an account?'}
                  </Typography>
                </MuiLink>
              </Grid>
            </Grid>
          </>
        </CardContent>
      </Card>
    </Container>
  );
}
