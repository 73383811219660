import { Dispatch, SetStateAction } from 'react';
import { styled } from '@mui/material';
import InputError from '@/components/InputError';
import SectionBorder from '@/components/SectionBorder';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import type { MembershipRole } from '@/types/types';
import { permissions, availableRoles } from '@/constants';
import { updateTeamMemberSchema } from '@/lib/validation/team';
import { FormikHelpers } from 'formik';
import MyFormik from '@/components/Formik';
import type { TeamMemberUpdateInput } from '@/types/formInput';
import type { User } from '@/types/api';
import { PTextField } from 'pragmatic-ui';
import SaveIcon from '@/components/icons/SaveIcon';
import { useTeamMemberUpdate } from '@/hooks/admin/useTeam';

interface Props {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  selectedUser: User | null;
}

// TODO: 
const isSelectedUserMembership = false;

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function TeamMemberManager({
  setDrawerOpen,
  selectedUser: user,
}: Props) {
  const roles = availableRoles.map((role) => role.name);
  const teamMemberUpdate = useTeamMemberUpdate();

  const handleSubmit = (
    values: TeamMemberUpdateInput,
    { resetForm }: FormikHelpers<TeamMemberUpdateInput>,
  ) => {
    // @ts-expect-error - typescript bug
    teamMemberUpdate.mutate(values);
    resetForm();
    setDrawerOpen(false);
  };

  const formikProps = {
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      role: user?.role || 'user',
    },
    validationSchema: updateTeamMemberSchema,
    onSubmit: handleSubmit,
  };

  return (
    <>
      {permissions.canAddTeamMembers ? (
        <>
          <SectionBorder />

          <MyFormik<TeamMemberUpdateInput> {...formikProps}>
            {({ handleSubmit, errors, resetForm }) => (
              <StyledForm onSubmit={handleSubmit}>
                {/* <!-- Member Email --> */}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box component="div">
                      <PTextField
                        variant="standard"
                        name="name"
                        type="name"
                        disabled={isSelectedUserMembership}
                        placeholder="Name"
                        InputProps={{
                          disableUnderline: false,
                          sx: { fontSize: '15px' },
                        }}
                        fullWidth
                      />
                      <InputError message={errors.name} sx={{ mt: 1 }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box component="div">
                      <PTextField
                        variant="standard"
                        name="email"
                        type="email"
                        disabled={isSelectedUserMembership}
                        placeholder="Email"
                        InputProps={{
                          disableUnderline: false,
                          sx: { fontSize: '15px' },
                        }}
                        fullWidth
                      />
                      <InputError message={errors.email} sx={{ mt: 1 }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <PTextField
                      name="role"
                      label="Role"
                      variant="standard"
                      className="config-text-fields"
                      fullWidth={true}
                      sx={{
                        mr: 4,
                        mt: 1,
                        minWidth: '150px',
                      }}
                      InputProps={{
                        disableUnderline: false,
                      }}
                      select
                      SelectProps={{
                        multiple: false,
                        sx: {
                          '& .MuiSvgIcon-root': {
                            color: '#F6AE2D',
                          },
                        },
                      }}
                    >
                      {roles.map((role) => (
                        <MenuItem
                          key={`role-${role}`}
                          value={role as MembershipRole}
                        >
                          {role}
                        </MenuItem>
                      ))}
                    </PTextField>
                    <InputError message={errors.role} sx={{ mt: 1 }} />
                  </Grid>
                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 8 }}>
                    <Button
                      sx={{ mr: 2 }}
                      onClick={() => {
                        resetForm();
                        setDrawerOpen(false);
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                      SAVE
                    </Button>
                  </Box>
                </Grid>
              </StyledForm>
            )}
          </MyFormik>
        </>
      ) : null}
    </>
  );
}
