import { useContext, useRef, useState } from 'react';
import UserContext from '@/context/user';
import FormSection from '@/components/FormSection';
import InputError from '@/components/InputError';
import { Box, Button, Grid } from '@mui/material';
import { userProfileSchema } from '@/lib/validation/userProfile';
import { FormikHelpers } from 'formik';
import MyFormik from '@/components/Formik';
import { PTextField } from 'pragmatic-ui';
import { ProfileInput } from '@/types/formInput';
import { useProfileUpdate } from '@/hooks/auth/useAuth';

export default function UpdateProfileInformationForm() {
  const { user } = useContext(UserContext);
  const profileUpdate = useProfileUpdate();
  // const [photoPreview, setPhotoPreview] = useState<string | null>(null);

  const photoRef = useRef<HTMLInputElement>(null);
  const isManagesProfilePhotos = true;

  const handleSubmit = (
    values: ProfileInput,
    { resetForm }: FormikHelpers<ProfileInput>,
  ) => {
    profileUpdate.mutate(values);
    resetForm();
  };

  const formikProps = {
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      photo: null,
    },
    validationSchema: userProfileSchema,
    onSubmit: handleSubmit,
  };

  function selectNewPhoto() {
    photoRef.current?.click();
  }

  // function updatePhotoPreview() {
  //   const photo = photoRef.current?.files?.[0];
  //   if (!photo) return;

  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     setPhotoPreview(e.target?.result as string);
  //   };

  //   reader.readAsDataURL(photo);
  // }

  // function deletePhoto() {
  //   setPhotoPreview(null);
  //   if (photoRef.current?.value) {
  //     photoRef.current.value = '';
  //   }
  // }

  return (
    <MyFormik<ProfileInput> {...formikProps}>
      {({ handleSubmit, isValid, isSubmitting, errors }) => (
        <FormSection
          onSubmit={handleSubmit}
          title={'Profile Information'}
          description={`Update your account's profile information and email address.`}
          renderActions={() => (
            <>
              <Button
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Save
              </Button>
            </>
          )}
        >
          {/* <!-- Profile Photo --> */}
          {/* {user && isManagesProfilePhotos ? (
            <div className="col-span-6 sm:col-span-4">
              <input
                type="file"
                className="hidden"
                ref={photoRef}
                onChange={updatePhotoPreview}
              /> */}

              {/* <InputLabel htmlFor="photo" value="Photo" /> */}

              {/* {photoPreview ? (
                <Box>
                  <span
                    className="block rounded-full w-20 h-20"
                    style={{
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      backgroundImage: `url('${photoPreview}')`,
                    }}
                  ></span>
                </Box>
              ) : (
                <Box>
                  <img
                    src={user.profile_photo_url}
                    alt={user.name}
                    className="rounded-full h-20 w-20 object-cover"
                  />
                </Box>
              )}

              <Button
                sx={{
                  mt: 2,
                  mr: 2,
                  mb: 4,
                }}
                type="button"
                variant="contained"
                onClick={selectNewPhoto}
              >
                Select A New Photo
              </Button>

              {user && user.profile_photo_path ? (
                <Button
                  type="button"
                  sx={{
                    mt: -2,
                  }}
                  variant="outlined"
                  onClick={deletePhoto}
                >
                  Remove Photo
                </Button>
              ) : null}

              <InputError message={errors.photo} sx={{ mt: 1 }} />
            </div>
          ) : null} */}

          <Grid container spacing={3} pl={4} sx={{ pl: { sm: 0, xs: 0 } }}>
            {/* <!-- Name --> */}
            <Grid item xs={12} sm={12}>
              <PTextField label="Name" type="text" name="name" fullWidth />
            </Grid>

            {/* <!-- Email --> */}
            <Grid item xs={12} sm={12}>
              <PTextField label="Email" name="email" type="email" fullWidth disabled={true} />
            </Grid>
          </Grid>
        </FormSection>
      )}
    </MyFormik>
  );
}
