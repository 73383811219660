import { styled, Grid, Box, Button } from '@mui/material';
import { PTextField } from 'pragmatic-ui';
import { useTeamCreate } from '@/hooks/admin/useTeams';
import { FormikHelpers, Field, FieldProps } from 'formik';
import MyFormik from '@/components/Formik';
import { createTeamSchema } from '@/lib/validation/team';
import SaveIcon from '@/components/icons/SaveIcon';
import { TeamInput } from '@/types/formInput';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function TeamCreate() {
  const teamCreate = useTeamCreate();

  const handleSubmit = (
    values: TeamInput,
    { resetForm }: FormikHelpers<TeamInput>,
  ) => {
    teamCreate.mutate(values);
    resetForm();
  };
  const formikProps = {
    initialValues: {
      name: '',
    },
    validationSchema: createTeamSchema,
    onSubmit: handleSubmit,
  };

  return (
    <MyFormik<TeamInput> {...formikProps}>
      {({ handleSubmit, resetForm }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className="col-span-6 sm:col-span-4">
            <Grid item xs={3}>
              <Field name={'name'}>
                {({ field }: { field: FieldProps<TeamInput> }) => {
                  return (
                    <PTextField
                      {...field}
                      name="name"
                      label="Create New Team"
                      placeholder="Enter Team Name"
                      fullWidth
                    />
                  );
                }}
              </Field>
            </Grid>
          </div>

          <Box display="flex" justifyContent="flex-end" sx={{ mt: 8 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={() => {
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              SAVE
            </Button>
          </Box>
        </StyledForm>
      )}
    </MyFormik>
  );
}
