import { useState } from 'react';
import { Drawer } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomerCreateTeamForm from '@/layouts/CustomerCreateTeamForm';

export default function CustomerCreateTeamDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={toggleDrawer(true)}
      >
        INVITE NEW
      </Button>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '30% !important',
            background: '#202D38',
            borderRadius: '12px 0px 0px 12px !important',
            color: '#fff',
          },
        }}
      >
        <div role="presentation">
          <Box>
            <CustomerCreateTeamForm setDrawerOpen={setDrawerOpen} />
          </Box>
        </div>
      </Drawer>
    </div>
  );
}
