export default function DocIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 3V2C4.57272 2 4.125 2.44772 4.125 3H5.125ZM19.125 21V22C19.6773 22 20.125 21.5523 20.125 21H19.125ZM5.125 21H4.125C4.125 21.5523 4.57272 22 5.125 22V21ZM14.125 3L14.8321 2.29289C14.6446 2.10536 14.3902 2 14.125 2V3ZM19.125 8H20.125C20.125 7.73478 20.0196 7.48043 19.8321 7.29289L19.125 8ZM8.125 16C7.57272 16 7.125 16.4477 7.125 17C7.125 17.5523 7.57272 18 8.125 18V16ZM16.125 18C16.6773 18 17.125 17.5523 17.125 17C17.125 16.4477 16.6773 16 16.125 16V18ZM8.125 12C7.57272 12 7.125 12.4477 7.125 13C7.125 13.5523 7.57272 14 8.125 14V12ZM14.125 14C14.6773 14 15.125 13.5523 15.125 13C15.125 12.4477 14.6773 12 14.125 12V14ZM19.125 20H5.125V22H19.125V20ZM6.125 21V3H4.125V21H6.125ZM5.125 4H14.125V2H5.125V4ZM18.125 8V21H20.125V8H18.125ZM13.4179 3.70711L18.4179 8.70711L19.8321 7.29289L14.8321 2.29289L13.4179 3.70711ZM12.125 3V7H14.125V3H12.125ZM15.125 10H19.125V8H15.125V10ZM12.125 7C12.125 8.65685 13.4681 10 15.125 10V8C14.5727 8 14.125 7.55228 14.125 7H12.125ZM8.125 18H16.125V16H8.125V18ZM8.125 14H14.125V12H8.125V14Z"
        fill="#F6AE2D"
      />
    </svg>
  );
}
