export default function PdfNormalIcon() {
  return (
    <svg
      width="27"
      height="33"
      viewBox="0 0 27 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7484 1.84723C14.7501 1.88201 14.7501 1.90119 14.7501 1.93955L14.7501 8.38003C14.75 8.74809 14.75 9.10653 14.7748 9.4101C14.802 9.74346 14.8663 10.1368 15.068 10.5327C15.3476 11.0815 15.7938 11.5277 16.3426 11.8073C16.7384 12.009 17.1318 12.0732 17.4652 12.1005C17.7687 12.1253 18.1272 12.1252 18.4952 12.1252L24.9358 12.1252C24.9741 12.1252 24.9932 12.1252 25.0279 12.1268C25.7481 12.1612 26.3811 12.7945 26.4151 13.5147C26.4168 13.5494 26.4168 13.5608 26.4167 13.5834V24.1438C26.4168 25.3177 26.4168 26.2865 26.3523 27.0757C26.2853 27.8955 26.1416 28.649 25.781 29.3568C25.2217 30.4544 24.3293 31.3468 23.2317 31.906C22.5239 32.2667 21.7704 32.4104 20.9507 32.4774C20.1614 32.5419 19.1926 32.5419 18.0187 32.5418H8.56482C7.39092 32.5419 6.42207 32.5419 5.63284 32.4774C4.81311 32.4104 4.05959 32.2667 3.35181 31.906C2.25419 31.3468 1.36181 30.4544 0.802546 29.3568C0.441913 28.649 0.298174 27.8955 0.231199 27.0757C0.166717 26.2865 0.166731 25.3176 0.166749 24.1438V8.85658C0.166731 7.68269 0.166717 6.71382 0.231199 5.92459C0.298174 5.10486 0.441913 4.35134 0.802546 3.64356C1.36181 2.54594 2.25419 1.65356 3.35181 1.0943C4.05959 0.733663 4.81311 0.589923 5.63284 0.522949C6.42207 0.458466 7.3909 0.45848 8.56479 0.458497H13.2917C13.3145 0.458488 13.3259 0.458484 13.3607 0.460128C14.0808 0.494195 14.714 1.12713 14.7484 1.84723Z"
        fill="url(#paint0_linear_9849_8397)"
      />
      <path
        d="M24.1835 9.10097C24.0081 9.20849 23.794 9.20849 23.3659 9.20848L18.8334 9.20842C18.425 9.20842 18.2208 9.20842 18.0649 9.12894C17.9277 9.05903 17.8161 8.94748 17.7462 8.81028C17.6667 8.65431 17.6667 8.45013 17.6667 8.04178L17.6668 3.50939C17.6668 3.08119 17.6668 2.86709 17.7743 2.6917C17.9261 2.44402 18.2839 2.29594 18.5663 2.36384C18.7664 2.41192 18.9059 2.55155 19.1848 2.83079L24.0445 7.69042C24.3237 7.96939 24.4633 8.10888 24.5114 8.3089C24.5793 8.59139 24.4312 8.94913 24.1835 9.10097Z"
        fill="url(#paint1_linear_9849_8397)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9849_8397"
          x1="27.7925"
          y1="8.20521"
          x2="-2.0702"
          y2="13.5248"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stop-color="#F26419" />
          <stop offset="0.62" stop-color="#CE4721" />
          <stop offset="0.82" stop-color="#BC3926" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9849_8397"
          x1="27.7925"
          y1="8.20521"
          x2="-2.0702"
          y2="13.5248"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stop-color="#F26419" />
          <stop offset="0.62" stop-color="#CE4721" />
          <stop offset="0.82" stop-color="#BC3926" />
        </linearGradient>
      </defs>
    </svg>
  );
}
