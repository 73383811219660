import { useCallback, useContext } from 'react';
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import api from '@/lib/api-client';
import { SnackBarContext } from 'pragmatic-ui';

const savedRoutesKey = ['saved-routes'];

async function getSavedRoutes(): Promise<any[]> {
  const response = await api.get(`/v1/admin/saved-routes`);
  return response.data;
}

export const useSavedRoutes = (
  options?: Omit<
    UseQueryOptions<any[], Error, any[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: savedRoutesKey,
    queryFn: getSavedRoutes,
    ...options,
  });

async function createSavedRoute(payload: any): Promise<any> {
  const response = await api.post('/v1/admin/saved-routes', payload);
  return response.data;
}

export const useCreateSavedRoute = (
  options?: Omit<UseMutationOptions<any, Error, any>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  const setSavedRoute = useCallback(
    (newSavedRoute: any) => {
      queryClient.setQueryData<any[]>(savedRoutesKey, (oldData) => {
        return oldData ? [...oldData, newSavedRoute] : [newSavedRoute];
      });
    },
    [queryClient],
  );

  return useMutation({
    mutationFn: createSavedRoute,
    ...options,
    onSuccess: (savedRoute, ...rest) => {
      setSavedRoute(savedRoute);
      queryClient.invalidateQueries({ queryKey: savedRoutesKey });
      options?.onSuccess?.(savedRoute, ...rest);
      showSnack('Successfully saved route', 'success');
    },
    onError: (error) => {
      console.log('the error', error);
      showSnack(
        'There was an unexpected error, please try again later',
        'error',
      );
    },
  });
};

async function updateSavedRoute(updatedRoute: any): Promise<any> {
  const response = await api.put(
    `/v1/admin/saved-routes/${updatedRoute?._id}`,
    updatedRoute,
  );
  return response.data;
}

export const useUpdateSavedRoute = (
  options?: Omit<UseMutationOptions<any, Error, any>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  const { showSnack } = useContext(SnackBarContext);

  return useMutation({
    mutationFn: updateSavedRoute,
    onError: () => {
      showSnack('There was an error updating the route', 'error');
    },
    onSuccess: (updatedRoute, ...rest) => {
      queryClient.invalidateQueries({ queryKey: savedRoutesKey });
      options?.onSuccess?.(updatedRoute, ...rest);
      showSnack('Successfully updated the route', 'success');
    },
  });
};

async function deleteSavedRoute(id: string) {
  const response = await api.delete(`/v1/admin/saved-routes/${id}`);
  return response.data;
}

export const useDeleteSavedRoute = (
  options?: Omit<UseMutationOptions<void, Error, string>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSavedRoute,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: savedRoutesKey });
      options?.onSuccess?.(...args);
    },
  });
};
