import { Box } from '@mui/material';
import CustomerVehicleDetail from '@/features/vehicles/CustomerVehicleDetail';

export default function Vehicle() {
  return (
    <Box px={0.5}>
      <CustomerVehicleDetail/>
    </Box>
  );
}
