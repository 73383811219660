import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  Fab,
  Typography,
  IconButton,
} from '@mui/material';
import { debounce } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';

import CustomMap from './AnotherNewMap2';
import WaypointMarker from './icons/WaypointMarkerIcon';
import SwapIcon from './icons/SwapIcon';
import HidePointsIcon from './icons/HidePointsIcon';
import StartLocationIcon from './icons/StartLocationIcon';
import EndLocationIcon from './icons/EndLocationIcon';
import ArrowAltRightIcon from './icons/ArrowAltRightIcon';
import ArrowAltLeftIcon from './icons/ArrowAltLeftIcon';
import {
  useCreateSavedRoute,
  useUpdateSavedRoute,
} from '@/hooks/admin/useSavedRoutes';

interface Waypoint {
  name?: string;
  coordinates: [number, number];
}
interface RouteData {
  _id?: string;
  start?: {
    name: string;
    coordinates: [number, number];
  } | null;
  end?: {
    name: string;
    coordinates: [number, number];
  } | null;
  waypoints?: Waypoint[];
  geojsonData: any[];
}

interface Suggestion {
  id: string;
  place_name: string;
  center: [number, number];
}

/**
 * The component can optionally receive a pre-existing RouteData object
 * to re-render a saved route (routeData?), plus other props controlling UI.
 *
 * If you only want to pass in multiple geojson data and not a saved route,
 * you can omit routeData.
 */
interface MapWithAutocompleteProps {
  /**
   * If provided, your component can initialize from this saved route object.
   */
  routeData?: RouteData;

  company: string;
  configuration: string;

  /**
   * Whether or not the side bar is displayed and a function to toggle it.
   */
  displayLeftBar: boolean;
  switchLeftBar: () => void;
}

const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({
  routeData,
  configuration,
  company,
  displayLeftBar,
  switchLeftBar,
}) => {
  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');
  const [startSuggestions, setStartSuggestions] = useState<Suggestion[]>([]);
  const [endSuggestions, setEndSuggestions] = useState<Suggestion[]>([]);

  const [startPoint, setStartPoint] = useState<[number, number] | null>(null);
  const [endPoint, setEndPoint] = useState<[number, number] | null>(null);

  const [waypoints, setWaypoints] = useState<[number, number][]>([]);
  const [isWaypointMode, setIsWaypointMode] = useState(false);

  const [startValue, setStartValue] = useState<Suggestion | null>(null);
  const [endValue, setEndValue] = useState<Suggestion | null>(null);

  const [showHighRiskOnly, setShowHighRiskOnly] = useState(false);

  const createSavedRoute = useCreateSavedRoute();
  const updateSavedRoute = useUpdateSavedRoute();

  /**
   * If your routeData contains multiple GeoJSON objects, store them in an array.
   * This way you can pass them all down to the map.
   */
  const [geojsonArray, setGeojsonArray] = useState<any[]>([]);

  useEffect(() => {
    if (routeData) {
      const { start, end, waypoints, geojsonData } = routeData;

      if (start) {
        setStartInput(start.name);
        setStartPoint(start.coordinates);
        setStartValue({
          id: 'start',
          place_name: start.name,
          center: start.coordinates,
        });
      }

      if (end) {
        setEndInput(end.name);
        setEndPoint(end.coordinates);
        setEndValue({
          id: 'end',
          place_name: end.name,
          center: end.coordinates,
        });
      }

      if (waypoints) {
        setWaypoints(waypoints.map((wp) => wp.coordinates));
      }

      if (geojsonData) {
        setGeojsonArray(geojsonData);
      }
    }
  }, [routeData]);

  const accessToken =
    'pk.eyJ1Ijoia2FyYW5wMyIsImEiOiJjbHV3Mno4eGowY3BmMmtud3h2cWh0ZXEyIn0.Jpsmhecf2-ZSoAUAjzpRxw';

  const fetchSuggestions = async (
    input: string,
    setter: React.Dispatch<React.SetStateAction<Suggestion[]>>,
  ) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      input,
    )}.json?access_token=${accessToken}&autocomplete=true&limit=5&country=nz`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      const suggestions: Suggestion[] = data.features.map((feature: any) => ({
        id: feature.id,
        place_name: feature.place_name,
        center: feature.center,
      }));

      setter(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debouncedFetchSuggestionsStart = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setStartSuggestions);
    }, 500),
    [],
  );

  const debouncedFetchSuggestionsEnd = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setEndSuggestions);
    }, 500),
    [],
  );

  const toggleWaypointMode = () => {
    setIsWaypointMode(!isWaypointMode);
  };

  const handleSaveRoute = useCallback(async () => {
    const routeToSave = {
      start: startPoint
        ? {
            name: startInput,
            coordinates: startPoint,
          }
        : null,
      end: endPoint
        ? {
            name: endInput,
            coordinates: endPoint,
          }
        : null,
      waypoints: waypoints.map((coords) => ({
        coordinates: coords,
      })),
      configuration,
      company,
    };

    createSavedRoute.mutate(routeToSave);
  }, [startInput, endInput, startPoint, endPoint, waypoints, geojsonArray]);

  const handleUpdateSaveRoute = useCallback(async () => {
    const routeToUpdate = {
      _id: routeData?._id,
      start: startPoint
        ? {
            name: startInput,
            coordinates: startPoint,
          }
        : null,
      end: endPoint
        ? {
            name: endInput,
            coordinates: endPoint,
          }
        : null,
      waypoints: waypoints.map((coords) => ({
        coordinates: coords,
      })),
      configuration,
      company,
    };

    updateSavedRoute.mutate(routeToUpdate);
  }, [startInput, endInput, startPoint, endPoint, waypoints, geojsonArray]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginTop={2}
        gap={2}
        sx={{
          position: 'absolute',
          zIndex: '1',
          marginTop: '20px',
          width: displayLeftBar ? '60%' : '60%',
          paddingLeft: displayLeftBar ? '20px' : '18%',
        }}
      >
        <Autocomplete<Suggestion>
          options={startSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x} // disables default filtering
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={startInput}
          value={startValue}
          onInputChange={(_event, value) => {
            setStartInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsStart(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setStartPoint(value.center);
            } else {
              setStartPoint(null);
            }
            setStartValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Box component="div" display="flex" justifyContent="start">
                  <StartLocationIcon />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                  >
                    Start Location
                  </Typography>
                </Box>
              }
              variant="filled"
              fullWidth
            />
          )}
          style={{ width: '45%' }}
        />

        <IconButton
          sx={{ marginTop: '25px' }}
          onClick={() => {
            const tempStartPoint = startPoint;
            const tempStartInput = startInput;
            const tempStartValue = startValue;

            setStartPoint(endPoint);
            setStartInput(endInput);
            setStartValue(endValue);

            setEndPoint(tempStartPoint);
            setEndInput(tempStartInput);
            setEndValue(tempStartValue);
          }}
        >
          <SwapIcon />
        </IconButton>

        <Autocomplete<Suggestion>
          options={endSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x} // disables default filtering
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={endInput}
          value={endValue}
          onInputChange={(_event, value) => {
            setEndInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsEnd(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setEndPoint(value.center);
            } else {
              setEndPoint(null);
            }
            setEndValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Box component="div" display="flex" justifyContent="start">
                  <EndLocationIcon />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                  >
                    End Location
                  </Typography>
                </Box>
              }
              variant="filled"
              fullWidth
            />
          )}
          style={{ width: '45%' }}
        />

        <Fab
          variant="circular"
          onClick={switchLeftBar}
          sx={{
            backgroundColor: '#121212',
            position: 'absolute',
            left: '0px',
            top: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,
            borderRadius: 0,
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
            '&:hover': {
              backgroundColor: '#121212',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#222',
              borderRadius: '50%',
              width: '45px',
              height: '45px',
            }}
          >
            {displayLeftBar && <ArrowAltLeftIcon color="#F6AE2D" />}
            {!displayLeftBar && <ArrowAltRightIcon color="#F6AE2D" />}
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={toggleWaypointMode}
          sx={{
            backgroundColor: 'primary.main',
            position: 'absolute',
            left: '10px',
            top: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <WaypointMarker
              style={
                isWaypointMode ? { position: 'relative', left: '4px' } : {}
              }
            />
            {isWaypointMode ? (
              <Typography
                variant="caption"
                style={{
                  color: '#000',
                  fontSize: '16px',
                  marginTop: '-25px',
                  textAlign: 'center',
                }}
              >
                +
              </Typography>
            ) : null}
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={() => setShowHighRiskOnly(!showHighRiskOnly)}
          sx={{
            backgroundColor: 'secondary',
            position: 'absolute',
            left: '10px',
            top: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <HidePointsIcon
              circleStroke={showHighRiskOnly ? 'white' : 'black'}
            />
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={routeData?._id ? handleUpdateSaveRoute : handleSaveRoute}
          sx={{
            backgroundColor: 'primary.main',
            position: 'absolute',
            left: '10px',
            top: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SaveIcon />
        </Fab>
      </Box>

      <CustomMap
        geojsonData={geojsonArray}
        startPoint={startPoint}
        endPoint={endPoint}
        waypoints={waypoints}
        setWaypoints={setWaypoints}
        isWaypointMode={isWaypointMode}
        showHighRiskOnly={showHighRiskOnly}
        displayLeftBar={displayLeftBar}
      />
    </Box>
  );
};

export default MapWithAutocomplete;
