import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import bbox from '@turf/bbox';

interface CustomMapProps {
  /**
   * An array of GeoJSON FeatureCollections representing different datasets
   * that you want to display on the map.
   */
  geojsonData: GeoJSON.FeatureCollection[];

  /**
   * Optional start point (longitude, latitude) for a route.
   */
  startPoint?: [number, number] | null;

  /**
   * Optional end point (longitude, latitude) for a route.
   */
  endPoint?: [number, number] | null;

  /**
   * Any waypoints (longitude, latitude) in between start and end.
   */
  waypoints: [number, number][];

  /**
   * Setter for updating the array of waypoints (e.g., when user drags a waypoint).
   */
  setWaypoints: React.Dispatch<React.SetStateAction<[number, number][]>>;

  /**
   * Whether the map is in "waypoint mode" (clicks add new waypoints).
   */
  isWaypointMode: boolean;

  /**
   * Whether or not to show only "High Risk" or "Do Not Cross" points.
   * (Your code checks for position === "do not cross".)
   */
  showHighRiskOnly: boolean;

  /**
   * Whether the left sidebar is displayed, for resizing the map accordingly.
   */
  displayLeftBar: boolean;
}

const markerStyles = {
  startMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
  endMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
};

const CustomMap: React.FC<CustomMapProps> = ({
  geojsonData,
  startPoint = null,
  endPoint = null,
  waypoints = [],
  setWaypoints,
  isWaypointMode,
  showHighRiskOnly,
  displayLeftBar,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const waypointMarkersRef = useRef<mapboxgl.Marker[]>([]);
  const [localWaypoints, setLocalWaypoints] =
    useState<[number, number][]>(waypoints);

  useEffect(() => {
    setLocalWaypoints(waypoints);
  }, [waypoints]);

  const [shouldFitBounds, setShouldFitBounds] = useState(true);

  const startMarkerRef = useRef<mapboxgl.Marker | null>(null);
  const endMarkerRef = useRef<mapboxgl.Marker | null>(null);

  mapboxgl.accessToken =
    'pk.eyJ1Ijoia2FyYW5wMyIsImEiOiJjbHV3Mno4eGowY3BmMmtud3h2cWh0ZXEyIn0.Jpsmhecf2-ZSoAUAjzpRxw';

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current ?? '',
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [174.76555, -36.84846],
      zoom: 8,
    });

    mapRef.current = map;

    // Navigation controls
    mapRef.current?.addControl(
      new mapboxgl.NavigationControl(),
      'bottom-right',
    );

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !startPoint || !endPoint) return;

    const fetchRoute = async () => {
      const allWaypoints = [...localWaypoints];
      const coords = [startPoint, ...allWaypoints, endPoint];
      const coordsString = coords.map((c) => c.join(',')).join(';');

      const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordsString}?geometries=geojson&overview=full&steps=true&access_token=${mapboxgl.accessToken}`;

      try {
        const response = await fetch(directionsUrl);
        const data = await response.json();

        if (!data.routes || !data.routes.length) {
          console.error('No routes found.');
          return;
        }

        const route = data.routes[0].geometry;
        const routeGeoJSON: GeoJSON.Feature = {
          type: 'Feature',
          properties: {},
          geometry: route,
        };

        const addOrUpdateRouteLayer = () => {
          if (map.getSource('route')) {
            (map.getSource('route') as mapboxgl.GeoJSONSource).setData(
              routeGeoJSON,
            );
          } else {
            map.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            map.addLayer({
              id: 'route-layer',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': 'rgb(83, 143, 223)',
                'line-width': 4,
              },
            });
          }

          // Fit map to route once
          const [minLng, minLat, maxLng, maxLat] = bbox(routeGeoJSON);
          if (shouldFitBounds) {
            map.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 100,
                // Only allow the map to zoom out, not in
                maxZoom: map.getZoom(),
              },
            );
            setShouldFitBounds(false);
          }
        };

        addOrUpdateRouteLayer();
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    };

    if (map.isStyleLoaded()) {
      fetchRoute();
    } else {
      map.on('load', fetchRoute);
    }
  }, [startPoint, endPoint, localWaypoints, shouldFitBounds]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const addOrUpdateDataLayers = () => {
      geojsonData.forEach((data, index) => {
        const sourceId = `geojson-data-${index}`;
        const pointsLayerId = `points-layer-${index}`;
        const numbersLayerId = `points-numbers-${index}`;

        // If the source doesn't exist, add it
        if (!map.getSource(sourceId)) {
          map.addSource(sourceId, {
            type: 'geojson',
            data: data,
          });
        } else {
          // If the source does exist, update its data
          const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
          source.setData(data);
        }

        // Points layer
        if (!map.getLayer(pointsLayerId)) {
          map.addLayer({
            id: pointsLayerId,
            type: 'circle',
            source: sourceId,
            paint: {
              'circle-radius': 10,
              'circle-stroke-width': 4,
              'circle-stroke-color': '#F44336',
              'circle-color': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                '#FF4500',
                [
                  'match',
                  ['downcase', ['get', 'risk']],
                  'not significant',
                  '#FFF',
                  'low',
                  '#FFF',
                  /* default */
                  '#FFF',
                ],
              ],
            },
          });
        }

        // Points numbers layer
        if (!map.getLayer(numbersLayerId)) {
          map.addLayer({
            id: numbersLayerId,
            type: 'symbol',
            source: sourceId,
            layout: {
              'text-field': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                '—', // If position = do not cross
                [
                  'match',
                  ['downcase', ['get', 'risk']],
                  'not significant',
                  ['get', 'speed'],
                  'low',
                  ['get', 'speed'],
                  /* default */
                  ['get', 'speed'],
                ],
              ],
              'text-size': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                14,
                12,
              ],
              'text-font': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                ['DIN Pro Bold', 'Arial Unicode MS Bold'],
                ['DIN Pro Medium', 'Arial Unicode MS Regular'],
              ],
              'text-allow-overlap': true,
              'text-ignore-placement': true,
              'text-anchor': 'center',
            },
            paint: {
              'text-color': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                '#FFFFFF',
                '#000000',
              ],
            },
          });
        }

        // Add event listeners for this layer
        // Because we're using unique IDs, we bind events to "points-layer-{index}"
        map.on('click', pointsLayerId, (e: any) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const properties = e.features[0].properties;
          new mapboxgl.Popup({ className: 'custom-popup' })
            .setLngLat(coordinates)
            .setHTML(
              `<div>
                <h3 style="width: 85%; margin-bottom: 5px; font-size: 16px">${properties.bridgename}</h3>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Owner:</strong> ${properties.owner}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Area:</strong> ${properties.area}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Risk:</strong> ${properties.risk}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Permit number:</strong> ${properties.permit}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Expiry:</strong> ${properties.expiry}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Speed:</strong> ${properties.speed} km/hr</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Position:</strong> ${properties.position}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Coordinates:</strong> ${properties.coordinates}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Notes:</strong> ${properties.notes}</p>
                <p style="font-size: 15px"><strong>VAI:</strong> ${properties.vai}</p>
              </div>`,
            )
            .addTo(map);
        });

        map.on('mouseenter', pointsLayerId, () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', pointsLayerId, () => {
          map.getCanvas().style.cursor = '';
        });
      });

      // Add navigation control (only once, if you like)
      if (!map.getContainer().querySelector('.mapboxgl-ctrl-bottom-right')) {
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
      }
    };

    if (map.isStyleLoaded()) {
      addOrUpdateDataLayers();
    } else {
      map.once('styledata', addOrUpdateDataLayers);
    }
  }, [geojsonData]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const applyFilters = () => {
      geojsonData.forEach((_data, index) => {
        const pointsLayerId = `points-layer-${index}`;
        const numbersLayerId = `points-numbers-${index}`;

        if (!map.getLayer(pointsLayerId) || !map.getLayer(numbersLayerId))
          return;

        if (showHighRiskOnly) {
          // Filter so position must be "do not cross"
          map.setFilter(pointsLayerId, [
            '==',
            ['downcase', ['get', 'position']],
            'do not cross',
          ]);
          map.setFilter(numbersLayerId, [
            '==',
            ['downcase', ['get', 'position']],
            'do not cross',
          ]);
        } else {
          // Remove any filters
          map.setFilter(pointsLayerId, null);
          map.setFilter(numbersLayerId, null);
        }
      });
    };

    if (map.isStyleLoaded()) {
      applyFilters();
    } else {
      map.on('load', applyFilters);
    }
  }, [showHighRiskOnly, geojsonData]);

  // useEffect(() => {
  //   const map = mapRef.current;
  //   if (!map) return;

  //   const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
  //     if (!isWaypointMode) return;
  //     const newWaypoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
  //     setWaypoints((prev) => [...prev, newWaypoint]);
  //   };

  //   map.on('click', handleMapClick);
  //   return () => {
  //     map.off('click', handleMapClick);
  //   };
  // }, [isWaypointMode, setWaypoints]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
      if (!isWaypointMode) return;
      const newWaypoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      updateWaypoints((prev: any) => [...prev, newWaypoint]);
    };

    map.on('click', handleMapClick);
    return () => {
      map.off('click', handleMapClick);
    };
  }, [isWaypointMode]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    // Remove existing waypoint source/layer if they exist
    if (map.getLayer('waypoints')) map.removeLayer('waypoints');
    if (map.getSource('waypoints')) map.removeSource('waypoints');

    if (localWaypoints.length > 0) {
      map.addSource('waypoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: localWaypoints.map((coords, i) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coords,
            },
            properties: {
              id: `waypoint-${i}`,
            },
          })),
        },
      });

      map.addLayer({
        id: 'waypoints',
        type: 'circle',
        source: 'waypoints',
        paint: {
          'circle-radius': 6,
          'circle-color': '#FFD700',
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
          'circle-stroke-opacity': 0.5,
        },
      });
    }
  }, [localWaypoints, waypoints]);

  // useEffect(() => {
  //   const map = mapRef.current;
  //   if (!map) return;

  //   const updateMarkers = () => {
  //     // Remove old markers if they exist
  //     if (startMarkerRef.current) {
  //       startMarkerRef.current.remove();
  //       startMarkerRef.current = null;
  //     }
  //     if (endMarkerRef.current) {
  //       endMarkerRef.current.remove();
  //       endMarkerRef.current = null;
  //     }
  //     waypointMarkersRef.current.forEach((m) => m.remove());
  //     waypointMarkersRef.current = [];

  //     // Add start marker
  //     if (startPoint) {
  //       const startEl = document.createElement('div');
  //       startEl.innerHTML = `
  //         <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
  //           <circle opacity="0.3" cx="32" cy="32" r="31.5" fill="#3C91E6" stroke="#3C91E6"/>
  //           <g transform="translate(21, 21) scale(1)">
  //             <path d="M11 21.2663C16.7906 21.2663 21.5 16.6522 21.5 10.9417C21.5 5.23134 16.7906 0.617188 11 0.617188C5.20939 0.617188 0.5 5.23134 0.5 10.9417C0.5 16.6522 5.20939 21.2663 11 21.2663ZM16.9286 10.9417C16.9286 14.0529 14.3411 16.6949 11 16.6949C7.65894 16.6949 5.07143 14.0529 5.07143 10.9417C5.07143 7.83061 7.65894 5.18862 11 5.18862C14.3411 5.18862 16.9286 7.83061 16.9286 10.9417Z" fill="#C2EFEB" stroke="#C2EFEB"/>
  //           </g>
  //         </svg>`;

  //       Object.assign(startEl.style, {
  //         ...markerStyles.startMarker,
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       });

  //       startMarkerRef.current = new mapboxgl.Marker({
  //         element: startEl,
  //         anchor: 'center',
  //       })
  //         .setLngLat(startPoint)
  //         .addTo(map);
  //     }

  //     // Add end marker
  //     if (endPoint) {
  //       const endEl = document.createElement('div');
  //       endEl.innerHTML = `
  //         <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
  //           <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2127 25.0763C17.0127 22.7847 23.8783 16.6799 23.8783 11.9168C23.8783 5.93374 19.0281 1.0835 13.045 1.0835C7.06192 1.0835 2.21167 5.93374 2.21167 11.9168C2.21167 16.6799 9.07732 22.7847 11.8773 25.0763C12.5635 25.638 13.5265 25.638 14.2127 25.0763ZM13.045 15.1668C14.8399 15.1668 16.295 13.7118 16.295 11.9168C16.295 10.1219 14.8399 8.66683 13.045 8.66683C11.2501 8.66683 9.795 10.1219 9.795 11.9168C9.795 13.7118 11.2501 15.1668 13.045 15.1668Z" fill="#F6AE2D"/>
  //         </svg>`;

  //       Object.assign(endEl.style, {
  //         ...markerStyles.endMarker,
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       });

  //       endMarkerRef.current = new mapboxgl.Marker({
  //         element: endEl,
  //         anchor: 'center',
  //       })
  //         .setLngLat(endPoint)
  //         .addTo(map);
  //     }

  //     // Add waypoint markers (draggable)
  //     waypoints.forEach((coords, index) => {
  //       const marker = new mapboxgl.Marker({
  //         color: 'rgb(246, 174, 45)',
  //         draggable: true,
  //       })
  //         .setLngLat(coords)
  //         .addTo(map);

  //       // Update waypoint on drag end
  //       marker.on('dragend', () => {
  //         const lngLat = marker.getLngLat();
  //         setWaypoints((prev) => {
  //           const updated = [...prev];
  //           updated[index] = [lngLat.lng, lngLat.lat];
  //           return updated;
  //         });
  //       });

  //       // Double-click to remove the waypoint
  //       marker.getElement().addEventListener('dblclick', (e) => {
  //         // Prevent map click from firing
  //         e.stopPropagation();
  //         setWaypoints((prev) => {
  //           const updated = [...prev];
  //           updated.splice(index, 1);
  //           return updated;
  //         });
  //         marker.remove();
  //       });

  //       waypointMarkersRef.current.push(marker);
  //     });
  //   };

  //   updateMarkers();

  //   return () => {
  //     if (startMarkerRef.current) {
  //       startMarkerRef.current.remove();
  //       startMarkerRef.current = null;
  //     }
  //     if (endMarkerRef.current) {
  //       endMarkerRef.current.remove();
  //       endMarkerRef.current = null;
  //     }
  //     waypointMarkersRef.current.forEach((m) => m.remove());
  //     waypointMarkersRef.current = [];
  //   };
  // }, [startPoint, endPoint, waypoints, setWaypoints]);

  const updateWaypoints = (newWaypoints: any) => {
    setLocalWaypoints(newWaypoints);
    setWaypoints(newWaypoints);
  };

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const updateMarkers = () => {
      if (startMarkerRef.current) {
        startMarkerRef.current.remove();
        startMarkerRef.current = null;
      }
      if (endMarkerRef.current) {
        endMarkerRef.current.remove();
        endMarkerRef.current = null;
      }
      waypointMarkersRef.current.forEach((m) => m.remove());
      waypointMarkersRef.current = [];

      if (startPoint) {
        const startEl = document.createElement('div');
        startEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <circle opacity="0.3" cx="32" cy="32" r="31.5" fill="#3C91E6" stroke="#3C91E6"/>
            <g transform="translate(21, 21) scale(1)">
              <path d="M11 21.2663C16.7906 21.2663 21.5 16.6522 21.5 10.9417C21.5 5.23134 16.7906 0.617188 11 0.617188C5.20939 0.617188 0.5 5.23134 0.5 10.9417C0.5 16.6522 5.20939 21.2663 11 21.2663ZM16.9286 10.9417C16.9286 14.0529 14.3411 16.6949 11 16.6949C7.65894 16.6949 5.07143 14.0529 5.07143 10.9417C5.07143 7.83061 7.65894 5.18862 11 5.18862C14.3411 5.18862 16.9286 7.83061 16.9286 10.9417Z" fill="#C2EFEB" stroke="#C2EFEB"/>
            </g>
          </svg>`;

        Object.assign(startEl.style, {
          ...markerStyles.startMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        startMarkerRef.current = new mapboxgl.Marker({
          element: startEl,
          anchor: 'center',
        })
          .setLngLat(startPoint)
          .addTo(map);
      }

      // Add end marker
      if (endPoint) {
        const endEl = document.createElement('div');
        endEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2127 25.0763C17.0127 22.7847 23.8783 16.6799 23.8783 11.9168C23.8783 5.93374 19.0281 1.0835 13.045 1.0835C7.06192 1.0835 2.21167 5.93374 2.21167 11.9168C2.21167 16.6799 9.07732 22.7847 11.8773 25.0763C12.5635 25.638 13.5265 25.638 14.2127 25.0763ZM13.045 15.1668C14.8399 15.1668 16.295 13.7118 16.295 11.9168C16.295 10.1219 14.8399 8.66683 13.045 8.66683C11.2501 8.66683 9.795 10.1219 9.795 11.9168C9.795 13.7118 11.2501 15.1668 13.045 15.1668Z" fill="#F6AE2D"/>
          </svg>`;

        Object.assign(endEl.style, {
          ...markerStyles.endMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        endMarkerRef.current = new mapboxgl.Marker({
          element: endEl,
          anchor: 'center',
        })
          .setLngLat(endPoint)
          .addTo(map);
      }

      // Add waypoint markers using localWaypoints instead of waypoints
      [...localWaypoints].forEach((coords, index) => {
        const marker = new mapboxgl.Marker({
          color: 'rgb(246, 174, 45)',
          draggable: true,
        })
          .setLngLat(coords)
          .addTo(map);

        // Update local waypoints on drag end
        marker.on('dragend', () => {
          const lngLat = marker.getLngLat();
          updateWaypoints((prev: any) => {
            const updated = [...prev];
            updated[index] = [lngLat.lng, lngLat.lat];
            return updated;
          });
        });

        // Double-click to remove waypoint
        marker.getElement().addEventListener('dblclick', (e) => {
          e.stopPropagation();
          updateWaypoints((prev: any) => {
            const updated = [...prev];
            updated.splice(index, 1);
            return updated;
          });
          marker.remove();
        });

        waypointMarkersRef.current.push(marker);
      });
    };

    updateMarkers();

    return () => {
      if (startMarkerRef.current) {
        startMarkerRef.current.remove();
        startMarkerRef.current = null;
      }
      if (endMarkerRef.current) {
        endMarkerRef.current.remove();
        endMarkerRef.current = null;
      }
      waypointMarkersRef.current.forEach((m) => m.remove());
      waypointMarkersRef.current = [];
    };
  }, [startPoint, endPoint, waypoints, localWaypoints]);

  useEffect(() => {
    mapRef?.current?.resize();
  }, [displayLeftBar]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        component="div"
        ref={mapContainerRef}
        style={{ width: '100%', height: '88vh' }}
      />
    </Box>
  );
};

export default CustomMap;
