export default function ArrowAltRightIcon({ color = 'white' }: any) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5708 7.93652H17.5708M17.5708 7.93652L11.5708 1.93652M17.5708 7.93652L11.5708 13.9365"
        stroke={color}
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
