import { Box, Fab, darken } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid-pro';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import CustomDataGrid from '@/components/CustomDataGrid';
import PermitStatusGreenIcon from '@/components/icons/PermitStatusGreenIcon';
import PermitStatusYellowIcon from '@/components/icons/PermitStatusYellowIcon';
import PermitStatusRedIcon from '@/components/icons/PermitStatusRedIcon';
import { useConfigurations } from '@/hooks/admin/useConfigurations';
import { useVehicles } from '@/hooks/admin/useVehicles';
import { useCompanies } from '@/hooks/admin/useCompanies';
import { useEffect, useMemo, useState } from 'react';
import { isDateExpired, isDateExpiring } from '@/lib/dates';

export default function PermitTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const permitStatus = searchParams.get('permitStatus');
  const { data: configurations = [] } = useConfigurations();
  const { data: vehicles = [] } = useVehicles();
  const { data: companies = [] } = useCompanies();

  const [filterStatus, setFilterStatus] = useState(permitStatus);

  // useEffect(() => {
  //   if (!filterStatus) {
  //     navigate(location.pathname, { replace: true });
  //   }
  // }, [filterStatus, navigate, location.pathname]);

  const initialState = useMemo(() => {
    const state: any = {
      pagination: { paginationModel: { pageSize: 10 } },
    };

    if (permitStatus) {
      state.filter = {
        filterModel: {
          items: [
            {
              field: 'status',
              operatorValue: 'equals',
              value: permitStatus,
            },
          ],
        },
      };
    }
    return state;
  }, [permitStatus, filterStatus]);

  const dataPermits = configurations.flatMap((config) => {
    const companyName =
      companies.find(({ _id }) => _id === config.company)?.name ?? '';
    const vehicleRego =
      vehicles.find(({ _id }) => _id === config.vehicle)?.rego ?? '';

    return config.permits.map((permit) => {
      return {
        ...permit,
        name: config.name,
        companyName,
        vehicleRego,
        configurationId: config._id,
      };
    });
  });

  const handleCellClick = (params: any) => {
    navigate(
      `/admin/configurations/edit/${params.row.configurationId}/${params.row._id}`,
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Client',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Configuration',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'vehicleRego',
      headerName: 'Vehicle',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'rca',
      headerName: 'RCA',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'issueDate',
      headerName: 'Issue',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry',
      minWidth: 75,
      flex: 1,
      type: 'date',
      valueGetter: (value: number) => new Date(value),
      valueFormatter: (value: Date) => value.toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Permit Status',
      minWidth: 75,
      flex: 1,
      valueGetter: (_, params) => {
        const expiryDate = new Date(params.expiryDate);

        if (isDateExpired(expiryDate)) {
          return 'expired';
        }

        if (isDateExpiring(expiryDate)) {
          return 'expiring';
        }

        return 'valid';
      },
      renderCell: (params: any) => {
        const expiryDate = new Date(params.row.expiryDate);

        if (isDateExpired(expiryDate)) {
          return (
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              height="100%"
            >
              <PermitStatusRedIcon />
            </Box>
          );
        }

        if (isDateExpiring(expiryDate)) {
          return (
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              height="100%"
            >
              <PermitStatusYellowIcon />
            </Box>
          );
        }

        return (
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            height="100%"
          >
            <PermitStatusGreenIcon />
          </Box>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            onClick={() => handleCellClick(params)}
          >
            <PencilOutlinedIcon />
          </Fab>
        </>
      ),
    },
  ];

  const getRowClassName = (params: any) => {
    const expiryDate = new Date(params.row.expiryDate);

    if (isDateExpired(expiryDate)) {
      return 'row-expired';
    }

    if (isDateExpiring(expiryDate)) {
      return 'row-expiring';
    }

    return 'row-valid';
  };

  return (
    <Box>
      <CustomDataGrid
        rows={dataPermits}
        columns={columns}
        getRowId={(row) => row._id}
        //hideFooter={true}
        disableRowSelectionOnClick={true}
        initialState={initialState}
        pagination={true}
        getRowClassName={getRowClassName}
        onFilterModelChange={(model) => {
          if (model.items.length === 0) {
            setFilterStatus(null);
          }
          return model;
        }}
      />
    </Box>
  );
}
