import * as Yup from 'yup';

export const userLoginSchema = Yup.object().shape({
  email: Yup.string().email().required('Please provide a valid email'),
  password: Yup.string().required('Password is required'),
  remember: Yup.string(),
});

export const userSignUpSchema = Yup.object().shape({
  name: Yup.string().required('Full name is required'),
  email: Yup.string().email().required('Please provide a valid email'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().required(
    'Password confirmation is required',
  ),
  terms: Yup.boolean(),
});

export const userInvitationSignUpSchema = Yup.object().shape({
  name: Yup.string().required('Full name is required'),
  email: Yup.string().email().required('Please provide a valid email'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().required(
    'Password confirmation is required',
  ),
  terms: Yup.boolean(),
});

export const twoFactorChallengeSchema = Yup.object().shape({
  code: Yup.number().required('Please provide a valid code'),
  recovery_code: Yup.string(),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
});

export const resetPasswordSchema = Yup.object().shape({
  token: Yup.string().required(),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().required(
    'Password confirmation is required',
  ),
});

export const resetInputSchema = Yup.object().shape({
  resetToken: Yup.string().required('ResetToken is required'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().required('Password (confirm) is required'),
});