import { Box } from '@mui/material';
import CustomerPermitTable from '@/features/permits/CustomerPermitTable';

export default function Permits() {
  return (
    <Box px={0.5}>
       <CustomerPermitTable/>
    </Box>
  );
}
