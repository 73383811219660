import * as Yup from 'yup';

export const userProfileSchema = Yup.object().shape({
  name: Yup.string().required('Full name is required'),
  email: Yup.string().email().required('Please provide a valid email'),
});

export const updatePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  password: Yup.string().required('New password is required'),
  password_confirmation: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const confirmPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
});

export const deleteUserSchema = Yup.object().shape({
  current_password: Yup.string().required('Password is required'),
});
