export default function RouteIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.875 18.9999H8.34451C6.42835 18.9999 4.87501 17.4465 4.875 15.5304V15.4491C4.875 13.5397 6.41779 11.9892 8.32716 11.9796L16.44 11.939C18.3561 11.9294 19.9017 10.3684 19.8922 8.45223V8.45223C19.8826 6.54282 18.3321 4.99998 16.4226 5H12.875"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="6.875"
        cy="5"
        r="3"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 21V15"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="16.875"
        y="15"
        width="5"
        height="4"
        stroke="#F6AE2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
