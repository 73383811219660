import { CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import ErrorPage from '@/pages/admin/ErrorPage';
import AppLayoutAdmin from '@/layouts/AppLayoutAdmin';

export default function AppRootAdmin() {
  const { pathname } = useLocation();

  return (
    <AppLayoutAdmin>
      <Suspense fallback={<CircularProgress />}>
        <ErrorBoundary key={pathname} fallback={<ErrorPage />}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </AppLayoutAdmin>
  );
}
