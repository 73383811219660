import api from '@/lib/api-client';
import { User } from '@/types/api';
import { UseQueryOptions, useMutation, useQueryClient, QueryKey, useQuery, UseMutationOptions } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { SnackBarContext } from 'pragmatic-ui';

const inviteKey = ['acceptInvite'];

async function acceptCustomerInvite(payload: FormData): Promise<User> {
    const response = await api.post('/v1/invites/accept', payload);
    return response.data;
}

export const useAcceptInvite = (
    options?: Omit<UseMutationOptions<User, Error, FormData>, 'mutationFn'>,
    ) => {
        const queryClient = useQueryClient();
        const { showSnack } = useContext(SnackBarContext);
    
        return useMutation({
            mutationFn: acceptCustomerInvite,
            ...options,
            onSuccess: (uesr, ...rest) => {
                queryClient.invalidateQueries({ queryKey: inviteKey });
                options?.onSuccess?.(uesr, ...rest);
                showSnack('Your account has been set up successfully. Please use email and password to log in.', 'success');
            },
            onError: () => {
                showSnack('There was an unexpected error, please try again', 'error');
            },
        });
    };