import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  message: JSX.Element;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export default function ConfirmationDialog({
  open,
  title,
  message,
  onClose,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        style: {
          backgroundColor: 'rgba(30, 30, 30, 0.90)',
          color: '#fff',
          padding: 0,
          border: '1px solid rgba(255, 255, 255, 0.23)',
          borderRadius: '14px',
          width: '300px',
        },
      }}
    >
      <DialogTitle
        display={'flex'}
        justifyContent={'center'}
        textAlign={'center'}
        sx={{ padding: '5px 16px !important' }}
      >
        <Typography
          variant="h6"
          sx={{ color: '#fff', fontWeight: 700, fontSize: '16px' }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          textAlign={'center'}
        >
          <Typography variant="body2">{message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          margin: 0,
          padding: 0,
        }}
      >
        <Button
          onClick={onConfirm}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '15px',
            borderTop: '1px solid rgba(255, 255, 255, 0.23)',
            borderRight: '1px solid rgba(255, 255, 255, 0.23)',
            borderLeft: 'none',
            borderBottom: 'none',
            textTransform: 'uppercase',
          }}
        >
          Yes
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '15px',
            borderTop: '1px solid rgba(255, 255, 255, 0.23)',
            borderLeft: '1px solid rgba(255, 255, 255, 0.23)',
            borderRight: 'none',
            borderBottom: 'none',
            marginLeft: '-1px !important',
            textTransform: 'uppercase',
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
